import { Table } from '../../../../../Admin/Components/Widget/Collection/Table';
import DateRangeFilterRenderer from '../../../../../Admin/template/collection/render/dateRangeFilter';
import LinkRenderer from '../../../../../Admin/template/collection/render/link';
import ActionRenderer from '../../../../../Admin/template/collection/render/action';
import CheckedRenderer from '../../../../../Admin/template/collection/render/checked';
import AccountApi from '../../../../../Api/Account';

export default class AccountUsersTable extends Table {
    id = "account-users-table";
    title = 'users';

    filters = [{
        id: 'user_name',
        label: 'name'
    },{
        id: 'user_username',
        label: 'username'
    },{
        id: 'created_at',
        labelFrom: 'created at (from)',
        labelTo: 'created at (to)',
        fitlerRender: DateRangeFilterRenderer
    }];

    columns = [{
        id: 'id',
        label: 'id',
        align: 'left',
        bind: 'id',
        sortable: true, 
    },{
        id: 'user_name',
        label: 'name',
        align: 'left',
        bind: 'user_name',
        sortable: true,
    },{
        id: 'user_username',
        label: 'username',
        align: 'left',
        bind: 'user_username',
        sortable: true,

    },{
        id: 'owner',
        label: 'owner',
        align: 'left',
        bind: 'owner',
        isChecked: (props) => props.row.owner === 1,
        render: CheckedRenderer
    },{
        id: 'assistant',
        label: 'assistant',
        align: 'left',
        bind: 'assistant',
        isChecked: (props) => props.row.assistant === 1,
        render: CheckedRenderer
    },{
        id: 'admin',
        label: 'admin',
        align: 'left',
        bind: 'admin',
        isChecked: (props) => props.row.admin === 1,
        render: CheckedRenderer
    },{
        id: 'active',
        label: 'active',
        align: 'left',
        bind: 'active',
        isChecked: (props) => {
            const { row } = props;
            return row.active === 1;
        },
        render: CheckedRenderer
    },{
        id: 'created_at',
        label: 'created at',
        align: 'right',
        bind: 'created_at',
        sortable: true,
    },{
        id: 'delete',
        align: 'right',
        type: 'secondary',
        text: 'delete',
        hidden: (props) => props.row.owner === 1,
        click: (props) => {
            const { component, row } = props;

            if (window.confirm(component.ucfirst('are you sure?'))) {
                const account = this.getPage().getParam('account');
                const id = row.id;
                
                AccountApi.account.user.delete(account,id).then(r => {
                    if (r.isValid()) {
                        component.retrieve();
                    }
                });
            }
        },
        canDisplay: (props) => props.component.getHelpers('auth').isOwnerOrAssistant(),
        render: ActionRenderer
    },{
        id: 'edit',
        align: 'right',
        text: 'edit',
        buildHref: (props) => {
            const { component, row } = props;
            const { account } = component.getPage().getParams();
            const { id } = row;
            return `/account/${account}/user/${id}`;
        },
        render: LinkRenderer
    }];

    getApi(options) {
        const { account } = this.getPage().getParams();
        return AccountApi.account.user.collection(account,options);
    }

    onLoad() {
        super.onLoad();
        this.retrieve();
    }

    afterRetrieve(r) {
        if (r.isValid()) {
            this.setCollectionData(r.getData());
        } else {
            //this.getPage().redirect('/accounts');
        }
    }
}