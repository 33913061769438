import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";

const UseStyles = makeStyles({
  dateTimeFrom: {
    marginBottom: "8px",
  },
});

const ExportDefault = (props) => {
  const { component, column } = props;
  const { id, labelFrom, labelTo } = column;
  const classes = UseStyles();

  let dateTimeFrom = component.getData(`default.filters.${id}-from`, null);
  let dateTimeTo = component.getData(`default.filters.${id}-to`, null);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        className={classes.dateTimeFrom}
        ampm={false}
        size="small"
        inputVariant="outlined"
        format="yyyy-MM-dd HH:mm:ss"
        fullWidth
        label={labelFrom ? component.ucfirst(labelFrom) : ""}
        value={dateTimeFrom}
        onChange={(date) => {
          date = component.getHelpers("dateTime").createDateTime(date);
          component.setData({ [`default.filters.${id}-from`]: date });
        }}
      />
      <DateTimePicker
        size="small"
        disabled={!dateTimeFrom}
        minDate={dateTimeFrom}
        ampm={false}
        inputVariant="outlined"
        format="yyyy-MM-dd HH:mm:ss"
        fullWidth
        label={labelTo ? component.ucfirst(labelTo) : ""}
        value={dateTimeTo}
        onChange={(date) => {
          date = component.getHelpers("dateTime").createDateTime(date);
          component.setData({ [`default.filters.${id}-to`]: date });
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default ExportDefault;
