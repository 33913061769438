import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TopBar from "../../../Components/Widget/TopBar";
import BottomBar from "../../../Components/Widget/BottomBar";
import theme from "../../../styles/themes/theme1";
import Accounts from "../../../Components/Collection/Accounts";
import HeaderMainFooterLayout from "../../../../../Admin/Components/Layout/HeaderMainFooter";

const pageTheme = theme();

const ExportDefault = function (props) {
  return (
    <ThemeProvider theme={pageTheme}>
      <TopBar />
      <Container maxWidth="xl">
        <HeaderMainFooterLayout
          page={true}
          paddingTop={80}
          paddingBottom={70}
          padding={3}
          renderMain={() => <Accounts />}
        />
      </Container>
      <BottomBar />
    </ThemeProvider>
  );
};

export default ExportDefault;
