import React from "react";
import TextField from "@material-ui/core/TextField";

const ExportDefault = (props) => {
  const { component, column } = props;
  const { id, label } = column;

  return (
    <TextField
      fullWidth
      label={label ? component.ucfirst(label) : ""}
      variant="outlined"
      size="small"
      value={component.getData(`default.filters.${id}`, "")}
      onChange={(e) =>
        component.setData({ [`default.filters.${id}`]: e.target.value })
      }
    />
  );
};

export default ExportDefault;
