import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';

const ExportDefault = (props) => {
    const { component, name } = props;
    const id = component.getId();

    return (
        component.getData(`error.fields.${name}`,[]).map((error, index) => {
            return error.message && <FormHelperText key={`${id}-${name}-error-${index}`}>{component.ucfirst(error.message)}</FormHelperText>;
        })
    );
};

export default ExportDefault;