import { Table } from "../Collection/Table";
import DateRangeFilterRenderer from "../../../template/collection/render/dateRangeFilter";
import ActionRenderer from "../../../template/collection/render/action";
import CheckedRenderer from "../../../template/collection/render/checked";
//import { PreviewRender } from "../../../../Admin/Components/Widget/Collection/Table/Render/Preview";
import { PreviewRender } from "../Collection/Table/Render/Preview";
import FileApi from "../../../../Api/File";

export default class FilesTable extends Table {
  id = "files-table";
  title = "files";

  filters = [
    {
      id: "name",
      label: "name",
    },
    {
      id: "slug",
      label: "slug",
    },
    {
      id: "type",
      label: "type",
    },
    {
      id: "size",
      label: "size",
    },
    {
      id: "created_at",
      labelFrom: "created at (from)",
      labelTo: "created at (to)",
      fitlerRender: DateRangeFilterRenderer,
    },
  ];

  columns = [
    {
      id: "image",
      width: "100px",
      height: "100px",
      errorSrc: (props) => "/images/not-found.png",
      render: PreviewRender,
    },
    {
      id: "preview",
      align: "right",
      type: "primary",
      text: "preview",
      click: (props) => {
        const { component, row } = props;
        component
          .getComponents()
          .findById("files-preview")
          .forEach((c) => c.open(row));
      },
      render: ActionRenderer,
    },
    {
      id: "id",
      label: "id",
      align: "left",
      bind: "id",
      sortable: true,
    },
    {
      id: "name",
      label: "name",
      align: "left",
      bind: "name",
      sortable: true,
    },
    {
      id: "slug",
      label: "slug",
      align: "left",
      bind: "slug",
      sortable: true,
    },
    {
      id: "scope",
      label: "scope",
      align: "left",
      bind: "scope",
      sortable: true,
    },
    {
      id: "type",
      label: "type",
      align: "left",
      bind: "type",
      sortable: true,
    },
    {
      id: "size",
      label: "size",
      align: "left",
      bind: "size",
      sortable: true,
    },
    {
      id: "position",
      label: "position",
      align: "left",
      bind: "position",
      sortable: true,
    },
    {
      id: "public",
      label: "public",
      align: "left",
      bind: "public",
      isChecked: (props) => {
        const { row } = props;
        return row.public === 1;
      },
      render: CheckedRenderer,
    },
    {
      id: "created_at",
      label: "created at",
      align: "right",
      bind: "created_at",
      sortable: true,
    },
    {
      id: "delete",
      align: "right",
      type: "secondary",
      text: "delete",
      hidden: (props) => {
        const { row } = props;
        return row.owner === 1;
      },
      click: (props) => {
        const { component, row } = props;

        if (window.confirm(component.ucfirst("are you sure?"))) {
          const params = this.getData("params");
          const { account, app, entity } = params;
          const { id } = row;

          FileApi.file
            .delete(account, app, id, { params: { entity } })
            .then((r) => {
              if (r.isValid()) {
                component.retrieve();
              }
            });
        }
      },
      render: ActionRenderer,
    },
    {
      id: "edit",
      align: "right",
      text: "edit",
      click: (props) => {
        const { component, row } = props;
        const { id } = row;
        const { account, app, repository, entity } = component.getData(
          "params",
          {}
        );

        component.getComponentsState().save("files-form-form", {
          default: {
            fields: {},
            state: "update",
          },
          params: {
            account,
            app,
            repository,
            entity,
            id,
          },
        });

        component
          .getComponents()
          .findById("files-form-modal")
          .forEach((c) => c.setTitle("edit file").open());
      },
      render: ActionRenderer,
    },
  ];

  buildPath(props) {
    const { component, row } = props;
    const { id } = row;
    const { account, app } = component.getData("params");
    const urlHelper = component.getHelpers("url");
    const token = component.getHelpers("auth").getToken();

    return (
      urlHelper.createApiUrl("file") +
      `/account/${account}/app/${app}/file/${id}/display?token=${token}`
    );
  }

  getApi(options) {
    const params = this.getData("params");
    const { account, app, entity, entity_slug } = params;

    options.params["entity"] = entity;
    options.params["entity_slug"] = entity_slug;

    return FileApi.file.collection(account, app, options);
  }

  afterRetrieve(r) {
    if (r.isValid()) {
      this.setCollectionData(r.getData());
    } else {
      this.getPage().redirect(`/`);
    }
  }
}
