import { Component } from '../../../../Core/Component';
import defaultTemplate from '../../templates/default/widgets/topBar';
import AccountApi from '../../../../Api/Account';

export default class TopBar extends Component {
    template = defaultTemplate;
    //widget = true;
    id = 'top-bar';

    onLoad(data) {
        super.onLoad(data);

        AccountApi.user.apps().then(r => {
            if (r.isValid()) {
                this.setData({'default.apps':r.getData()});
            }
        });
    }
}