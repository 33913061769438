import { Tabs } from '../../../../../Admin/Components/Widget/Tabs';
import Details from '../../../templates/default/panel/account/user/details';
import Apps from '../../../templates/default/panel/account/user/apps';

export default class AccountUserTabs extends Tabs {
    id = 'account-user-tabs';

    tabs = [{
        'id': 'details',
        'label': 'details',
        'render': Details
    },{
        'id': 'apps',
        'label': 'apps',
        'render': Apps
    }];
}