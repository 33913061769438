import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AccountUserAppsTable from '../../../../../Components/Collection/Account/User/Apps';
import WidgetsStyles from '../../../../../styles/widgets';

const UseStyles = makeStyles(WidgetsStyles().panel);

const ExportDefault = (props) => {
    const { component } = props;
    const classes = UseStyles();
    const { account } = component.getPage().getParams();
    const canAddApp = component.getHelpers('auth').isOwner(account);

    return (
        <React.Fragment>
            <Box className={classes.header}>
                {
                    canAddApp &&
                    <Button variant="contained" color="primary" onClick={() => component.getComponents().findById('new-app-modal').forEach(c => c.open())}>
                        {component.ucfirst('add new app')}
                    </Button>
                }
            </Box>
            <Box className={classes.main}>
                <AccountUserAppsTable />
            </Box>
        </React.Fragment>
    );
}

export default ExportDefault;