import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TopBar from "../../../../Components/Widget/TopBar";
import theme from "../../../../styles/themes/theme1";
import { Breadcrumbs } from "../../../../../../Admin/Components/Widget/Breadcrumbs";
import Tabs from "../../../../Components/Tabs/Account/User";
import BottomBar from "../../../../Components/Widget/BottomBar";
import NewAppModal from "../../../../Components/Modal/Account/User/NewApp";
import AppActionsModal from "../../../../Components/Modal/Account/User/App/Actions";
import HeaderMainFooterLayout from "../../../../../../Admin/Components/Layout/HeaderMainFooter";

const pageTheme = theme();

const PageBreadcrumbs = () => {
  return (
    <Breadcrumbs
      build={(component) => {
        return [
          {
            type: "link",
            label: component.ucfirst("accounts"),
            to: "/accounts",
          },
          {
            type: "link",
            label: component.getData("default.account.slug", ""),
            to: `/account/${component.getData("default.account.slug", "")}`,
          },
          {
            type: "text",
            label: component.getData("default.user.name", ""),
          },
        ];
      }}
    />
  );
};
const ExportDefault = function (props) {
  return (
    <ThemeProvider theme={pageTheme}>
      <TopBar />
      <NewAppModal />
      <AppActionsModal />
      <Container maxWidth="xl">
        <HeaderMainFooterLayout
          page={true}
          paddingTop={80}
          paddingBottom={70}
          renderHeader={() => <PageBreadcrumbs />}
          renderMain={() => <Tabs />}
        />
      </Container>
      <BottomBar />
    </ThemeProvider>
  );
};

export default ExportDefault;
