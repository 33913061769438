import { Table } from '../../../../../Admin/Components/Widget/Collection/Table';
import DateRangeFilterRenderer from '../../../../../Admin/template/collection/render/dateRangeFilter';
import LinkRenderer from '../../../../../Admin/template/collection/render/link';
import AccountApi from '../../../../../Api/Account';

export default class AccountAppsTable extends Table {
    id = "account-apps-table";
    title = 'apps';

    filters = [{
        id: 'slug',
        label: 'slug'
    },{
        id: 'created_at',
        labelFrom: 'created at (from)',
        labelTo: 'created at (to)',
        fitlerRender: DateRangeFilterRenderer
    }];

    columns = [{
        id: 'id',
        label: 'id',
        align: 'left',
        bind: 'id',
        sortable: true, 
    },{
        id: 'slug',
        label: 'name',
        align: 'left',
        bind: 'slug',
        sortable: true,

    },{
        id: 'created_at',
        label: 'created at',
        align: 'right',
        bind: 'created_at',
        sortable: true,
    },{
        id: 'edit',
        align: 'right',
        text: 'edit',
        buildHref: (props) => {
            const { component, row } = props;
            const { account } = component.getPage().getParams();
            const { slug } = row;
            return `/account/${account}/app/${slug}`;
        },
        render: LinkRenderer
    }];

    getApi(options) {
        const { account } = this.getPage().getParams();
        return AccountApi.account.app.collection(account,options);
    }

    onLoad() {
        super.onLoad();
        this.retrieve();
    }

    afterRetrieve(r) {
        if (r.isValid()) {
            this.setCollectionData(r.getData());
        } else {
            //this.getPage().redirect('/accounts');
        }
    }
}