import { Component } from '../../../Core/Component';
import templateDefault from '../../template/widget/breadcrumbs';

class Breadcrumbs extends Component {
    template = templateDefault;
    id = 'page-breadcrumbs';

    breadcrumbs = [];

    setBreadcrumbs(breadcrumbs) {
        this.breadcrumbs = breadcrumbs;
        return this;
    }

    getBreadcrumbs() {
        return this.breadcrumbs || [];
    }

    
}

export { Breadcrumbs };
export default new Breadcrumbs();