import Api from "../Core/Helpers/Api";

const accountApi = Api.create("account");

const ExportDefault = {
  user: {
    apps: function (options) {
      return accountApi.get(`user/apps`, options);
    },
    accounts: function (options) {
      return accountApi.get(`user/accounts`, options);
    },
    app: {
      accounts: function (app, options) {
        return accountApi.get(`user/app/${app}/accounts`, options);
      },
    },
  },
  account: {
    collection: function (options) {
      return accountApi.get(`account/collection`, options);
    },
    one: function (account, options) {
      return accountApi.get(`account/${account}`, options);
    },
    update: function (account, options) {
      return accountApi.put(`account/${account}`, options);
    },
    user: {
      collection: function (account, options) {
        return accountApi.get(`account/${account}/user/collection`, options);
      },
      create: function (account, options) {
        return accountApi.post(`account/${account}/user`, options);
      },
      one: function (account, id, options) {
        return accountApi.get(`account/${account}/user/${id}`, options);
      },
      update: function (account, id, options) {
        return accountApi.put(`account/${account}/user/${id}`, options);
      },
      delete: function (account, id, options) {
        return accountApi.delete(`account/${account}/user/${id}`, options);
      },
      app: {
        collection: function (account, id, options) {
          return accountApi.get(
            `account/${account}/user/${id}/app/collection`,
            options
          );
        },
        create: function (account, id, options) {
          return accountApi.post(`account/${account}/user/${id}/app`, options);
        },
        one: function (account, id, options) {
          return accountApi.get(`account/${account}/user/app/${id}`, options);
        },
        delete: function (account, id, options) {
          return accountApi.delete(
            `account/${account}/user/app/${id}`,
            options
          );
        },
        action: {
          update: function (account, id, options) {
            return accountApi.put(
              `account/${account}/user/app/${id}/action`,
              options
            );
          },
          collection: function (account, appSlug, options) {
            return accountApi.get(
              `account/${account}/user/app/${appSlug}/action/collection`,
              options
            );
          },
        },
      },
    },
    app: {
      collection: function (account, options) {
        return accountApi.get(`account/${account}/app/collection`, options);
      },
      one: function (account, app, options) {
        return accountApi.get(`account/${account}/app/${app}`, options);
      },
    },
  },
  //todo: delete in the future
  value: {
    collection: function (account, app, options) {
      return accountApi.get(
        `account/${account}/app/${app}/value/collection`,
        options
      );
    },
    create: function (account, app, options) {
      return accountApi.post(`account/${account}/app/${app}/value`, options);
    },
    one: function (account, app, id, options) {
      return accountApi.get(
        `account/${account}/app/${app}/value/${id}`,
        options
      );
    },
    update: function (account, app, id, options) {
      return accountApi.put(
        `account/${account}/app/${app}/value/${id}`,
        options
      );
    },
    delete: function (account, app, id, options) {
      return accountApi.delete(
        `account/${account}/app/${app}/value/${id}`,
        options
      );
    },
  },
  language: {
    collection: function (account, app, options) {
      return accountApi.get(
        `account/${account}/app/${app}/language/collection`,
        options
      );
    },
    create: function (account, app, options) {
      return accountApi.post(`account/${account}/app/${app}/language`, options);
    },
    one: function (account, app, id, options) {
      return accountApi.get(
        `account/${account}/app/${app}/language/${id}`,
        options
      );
    },
    update: function (account, app, id, options) {
      return accountApi.put(
        `account/${account}/app/${app}/language/${id}`,
        options
      );
    },
    delete: function (account, app, id, options) {
      return accountApi.delete(
        `account/${account}/app/${app}/language/${id}`,
        options
      );
    },
  },
  literal: {
    collection: function (account, app, options) {
      return accountApi.get(
        `account/${account}/app/${app}/literal/collection`,
        options
      );
    },
    create: function (account, app, options) {
      return accountApi.post(`account/${account}/app/${app}/literal`, options);
    },
    one: function (account, app, id, options) {
      return accountApi.get(
        `account/${account}/app/${app}/literal/${id}`,
        options
      );
    },
    update: function (account, app, id, options) {
      return accountApi.put(
        `account/${account}/app/${app}/literal/${id}`,
        options
      );
    },
    delete: function (account, app, id, options) {
      return accountApi.delete(
        `account/${account}/app/${app}/literal/${id}`,
        options
      );
    },
  },
  package: {
    collection: function (account, app, options) {
      return accountApi.get(
        `account/${account}/app/${app}/package/collection`,
        options
      );
    },
    create: function (account, app, options) {
      return accountApi.post(`account/${account}/app/${app}/package`, options);
    },
    one: function (account, app, id, options) {
      return accountApi.get(
        `account/${account}/app/${app}/package/${id}`,
        options
      );
    },
    update: function (account, app, id, options) {
      return accountApi.put(
        `account/${account}/app/${app}/package/${id}`,
        options
      );
    },
    delete: function (account, app, id, options) {
      return accountApi.delete(
        `account/${account}/app/${app}/package/${id}`,
        options
      );
    },
    import: function (account, app, id, options) {
      return accountApi.put(
        `account/${account}/app/${app}/package/${id}/import`,
        options
      );
    },
    remove: function (account, app, id, options) {
      return accountApi.delete(
        `account/${account}/app/${app}/package/${id}/remove`,
        options
      );
    },
  },
  translation: {
    collection: function (account, app, options) {
      return accountApi.get(
        `account/${account}/app/${app}/translation/collection`,
        options
      );
    },
    create: function (account, app, options) {
      return accountApi.post(
        `account/${account}/app/${app}/translation`,
        options
      );
    },
    one: function (account, app, id, options) {
      return accountApi.get(
        `account/${account}/app/${app}/translation/${id}`,
        options
      );
    },
    update: function (account, app, id, options) {
      return accountApi.put(
        `account/${account}/app/${app}/translation/${id}`,
        options
      );
    },
    delete: function (account, app, id, options) {
      return accountApi.delete(
        `account/${account}/app/${app}/translation/${id}`,
        options
      );
    },
  },
  log: {
    collection: function (account, app, options) {
      return accountApi.get(
        `account/${account}/app/${app}/log/collection`,
        options
      );
    },
    delete: function (account, app, id, options) {
      return accountApi.delete(
        `account/${account}/app/${app}/log/${id}`,
        options
      );
    },
  },
  job: {
    failed: {
      collection: function (account, app, options) {
        return accountApi.get(
          `account/${account}/app/${app}/job/failed/collection`,
          options
        );
      },
      run: function (account, app, id, options) {
        return accountApi.post(
          `account/${account}/app/${app}/job/failed/${id}/run`,
          options
        );
      },
      delete: function (account, app, id, options) {
        return accountApi.delete(
          `account/${account}/app/${app}/job/failed/${id}`,
          options
        );
      },
    },
  },
};

export default ExportDefault;
