import { Crud } from "../../../../../Core/Components/Form/Crud";
import templateDefault from "../../../../template/form/type/normal";
import TextField from "../../../../template/form/fields/type/text";
import SwitchField from "../../../../template/form/fields/type/switch";
import SelectField from "../../../../template/form/fields/type/select";
import ActionField from "../../../../template/form/fields/type/action";
import FileApi from "../../../../../Api/File";
import ContentApi from "../../../../../Api/Content";
//import ShareApi from "../../../../../Api/Share";

export default class FilesForm extends Crud {
  template = templateDefault;

  id = "files-form-form";
  collection = "files-table";

  fields = [
    {
      id: "slug",
      path: "slug",
      label: "slug",
      fullWidth: true,
      render: TextField,
    },
    {
      id: "name",
      path: "name",
      label: "name",
      fullWidth: true,
      render: TextField,
    },
    {
      id: "select-scope",
      path: "scope",
      label: "select scope",
      optionsPath: "scopes",
      fullWidth: true,
      getValue: (props) => {
        const { option } = props;
        return option;
      },
      getLabel: (props) => {
        const { form, option } = props;
        return form.ucfirst(option);
      },
      render: SelectField,
    },
    {
      id: "position",
      path: "position",
      label: "position",
      fullWidth: true,
      render: TextField,
    },
    {
      id: "public",
      path: "public",
      label: "public",
      render: SwitchField,
    },
    {
      id: "update",
      holder: "action",
      fullWidth: true,
      label: (props) => {
        const { form } = props;
        return form.isOnUpdate() ? "update" : "create";
      },
      render: ActionField,
    },
  ];

  onLoad(data) {
    super.onLoad(data);

    this.retrieveValues();
    this.retrieve();
  }

  async retrieveValues() {
    //const { account, app, repository } = this.getData("params", {});
    const { account, repository } = this.getData("params", {});
    const values = await ContentApi.value.collection(account, repository);

    if (values.isValid()) {
      const { rows = [] } = values.getData();

      const { value = "" } =
        rows.find((value) => value.slug === "file-scopes") || {};

      this.setData({
        "default.scopes": value ? value.split("\n") : ["default"],
      });
    }
  }

  getApi(options, type) {
    switch (type) {
      case "update": {
        const { account, app, id } = this.getData("params", {});
        const fields = this.getData("default.fields");
        const { slug, name, scope, position, entity } = fields;
        return FileApi.file.update(account, app, id, {
          entity,
          slug,
          name,
          scope,
          position,
          public: fields.public,
        });
      }
      case "retrieve": {
        const { account, app, id, entity } = this.getData("params", {});
        return FileApi.file.one(account, app, id, { params: { entity } });
      }
      default: {
        //
      }
    }
  }

  /*
    submit(options) {
        super.submit(options);   

        if (this.validate()) {
            this.switchPageLoader(true);
            const { account, app, id } = this.getData('params',{});
            const fields = this.getData('default.fields');
            const { name, slug } = fields;

            FileApi.file.update(account,app,id,{name,slug,public:fields.public}).then(r => {
                if (r.isValid()) {
                    this.setData({'success.message':r.getMessage()});
                    this.getComponents().findById('files-table').forEach(c => c.retrieve());
                } else {
                    this.setData({'error.message':r.getMessage()});
                }
                
                this.switchPageLoader(false);
            });
        }
    }

    retrieve() {
        const { account, app, id } = this.getData('params',{});
        
        this.switchPageLoader(true);

        FileApi.file.one(account,app,id).then(r => {
            if (r.isValid()) {
                this.setData({'default.fields':r.getData()});
            }
            
            this.switchPageLoader(false);
        });
    }
    */

  onValidate() {
    return {
      "fields.name": [
        {
          rule: "required",
        },
      ],
      "fields.scope": [
        {
          rule: "required",
        },
      ],
      "fields.position": [
        {
          rule: "required",
        },
        {
          rule: "number",
        },
      ],
      "fields.public": [
        {
          rule: "required",
        },
      ],
    };
  }
}
