import { Table } from '../../../../../../Admin/Components/Widget/Collection/Table';
import DateRangeFilterRenderer from '../../../../../../Admin/template/collection/render/dateRangeFilter';
import CheckedRenderer from '../../../../../../Admin/template/collection/render/checked';
import ActionRenderer from '../../../../../../Admin/template/collection/render/action';
import AccountApi from '../../../../../../Api/Account';

export default class AccountAppLanguageTable extends Table {
    id = "account-app-languages-table";
    title = 'languages';

    filters = [{
        id: 'slug',
        label: 'slug'
    },{
        id: 'name',
        label: 'name'
    },{
        id: 'created_at',
        labelFrom: 'created at (from)',
        labelTo: 'created at (to)',
        fitlerRender: DateRangeFilterRenderer
    }];

    columns = [{
        id: 'id',
        label: 'id',
        align: 'left',
        bind: 'id',
        sortable: true, 
    },{
        id: 'slug',
        label: 'slug',
        align: 'left',
        bind: 'slug',
        sortable: true,

    },{
        id: 'name',
        label: 'name',
        align: 'left',
        bind: 'name',
        sortable: true,

    },{
        id: 'public',
        label: 'public',
        align: 'left',
        bind: 'public',
        isChecked: (props) => {
            const { row } = props;
            return row.public === 1;
        },
        render: CheckedRenderer
    },{
        id: 'created_at',
        label: 'created at',
        align: 'right',
        bind: 'created_at',
        sortable: true,
    },{
        id: 'delete',
        align: 'right',
        type: 'secondary',
        text: 'delete',
        click: (props) => {
            const { component, row } = props;

            if (window.confirm(component.ucfirst('are you sure?'))) {
                const account = this.getPage().getParam('account');
                const app = this.getPage().getParam('app');
                const id = row.id;
                
                AccountApi.language.delete(account,app,id).then(r => {
                    if (r.isValid()) {
                        component.retrieve();
                    }
                });
            }
        },
        render: ActionRenderer
    },{
        id: 'edit',
        align: 'right',
        text: 'edit',
        click: (props) => {
            const { component, row } = props;
            const { id } = row;
            const { account, app } = component.getPage().getParams();

            component.getComponentsState().save('account-app-language-form',{
                'default': {
                    'fields': {},
                    'state': 'update'
                },
                'params': {
                    account,
                    app,
                    id
                }
            });

            component
                .getComponents()
                .findById('account-app-language-modal')
                .forEach(c => c.setTitle('edit language').open())
            ;
        },
        render: ActionRenderer
    }];

    getApi(options) {
        const { account, app } = this.getPage().getParams();
        return AccountApi.language.collection(account,app,options);
    }

    onLoad() {
        super.onLoad();
        this.retrieve();
    }

    afterRetrieve(r) {
        if (r.isValid()) {
            this.setCollectionData(r.getData());
        } else {
            //this.getPage().redirect('/accounts');
        }
    }
}