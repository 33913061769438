import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const ExportDefault = (props) => {
  const { component } = props;
  const id = component.getId();

  return (
    <Select
      id={id}
      labelId={`${id}-label`}
      value={component.getSelectedLanguage()}
      onChange={(e) => component.selectLanguage(e)}
    >
      {component.getLanguages().map((language) => (
        <MenuItem key={`${id}-lang-${language.slug}`} value={language.slug}>
          {component.ucfirst(language.slug)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ExportDefault;
