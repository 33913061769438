import { Component } from "../Component";

class Collection extends Component {
  columns = [];
  pageAmounts = [20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 400, 800, 1000];
  title = "";
  rowKey = "id";
  withFilters = true;
  withPagination = true;
  bordered = false;
  filters = [];
  permFilters = [];

  getTitle() {
    return this.title;
  }

  setPageAmounts(amounts) {
    return this.setData({ "default.pageAmounts": amounts });
  }

  getPageAmounts() {
    return this.getData("default.pageAmounts", this.pageAmounts);
  }

  setPageAmount(amount) {
    return this.setData({ "default.pageAmount": amount });
  }

  getPageAmount() {
    const defaultPageAmount = this.getPageAmounts()[0];
    return this.getData("default.pageAmount", defaultPageAmount);
  }

  changePage(page) {
    return this.setData({ "default.currentPage": page });
  }

  getCurrentPage() {
    return this.getData("default.currentPage", 0);
  }

  setOrderBy(column, type) {
    const { id, sortable } = column;

    if (sortable && id) {
      if (type) {
        this.setOrderType(type);
      }

      this.setData({ "default.orderBy": id });
    }

    return this;
  }

  getOrderedBy() {
    return this.getData("default.orderBy", "");
  }

  isOrderedBy(column) {
    const { id, sortable } = column;
    return id && sortable && this.getOrderedBy() === id;
  }

  setOrderType(type) {
    type = type || "asc";
    return this.setData({ "default.orderType": type });
  }

  changeOrderBy(column) {
    const orderType = this.getOrderType() === "asc" ? "desc" : "asc";
    this.setOrderBy(column, orderType);
    return this;
  }

  getOrderType() {
    return this.getData("default.orderType", "asc");
  }

  getColumnLabel(column) {
    return column.label || "";
  }

  getColumnAlign(column) {
    return column.align || "left";
  }

  isColumnFiltered(column) {
    return column.filtered;
  }

  isColumnSortable(column) {
    return column.sortable;
  }

  getTotal() {
    return this.getData("default.total", 0);
  }

  getRows() {
    return this.getData("default.rows", []);
  }

  getColumns() {
    return this.columns || [];
  }

  getFilters() {
    return this.filters || [];
  }

  getFiltersData() {
    let filters = this.getData("default.filters", {});
    return { ...filters, ...this.getPermFilterData() };
  }

  getPermFilterData() {
    return this.getData("default.permFilters", {});
  }

  // getFilterFromPage() {
  //   const filters = {};
  //   const query = this.getPage().getQuery();
  //   for (let name in query) {
  //     const filter = name.split("-");
  //     if (filter.length && filter[0] === "filter") {
  //       filters[filter[1]] = query[name];
  //     }
  //   }
  //   return filters;
  // }

  getFiltersAmount() {
    return (
      Object.keys(this.getFiltersData()).length -
      Object.keys(this.getPermFilterData()).length
    );
  }

  getFiltersCondition() {
    return this.getData("filters.condition", "and");
  }

  clearFilters() {
    return this.deleteData("default.filters");
  }

  getRowKey(row, defaultKey) {
    return row[this.rowKey] || defaultKey;
  }

  getColumnRowValue(column, row) {
    const { bind } = column;
    return this.getHelpers("dev").getObjectValue(row, bind, "");
  }

  setCollectionData(data) {
    const { rows, total } = data || {};

    return this.setData({
      "default.rows": rows || [],
      "default.total": total || 0,
    });
  }

  createRetrieveParams() {
    let params = this.getData("default.params.retrieve", {});

    params["page"] = this.getCurrentPage();
    params["amount"] = this.getPageAmount();

    const orderBy = this.getOrderedBy();
    const orderType = this.getOrderType();
    const filters = this.getFiltersData();
    const condition = this.getFiltersCondition();

    if (orderBy) {
      params["orderBy"] = orderBy;

      if (orderType) {
        params["orderType"] = orderType;
      }
    }

    if (filters) {
      for (let filter in filters) {
        const value = filters[filter];

        if (value !== "") {
          params[`f${filter}`] = encodeURIComponent(value);
        }
      }

      params["condition"] = condition;
    }

    return params;
  }

  getApi(options) {
    return this.api ? this.api(options) : null;
  }

  retrieve() {
    const params = this.createRetrieveParams();
    const api = this.getApi({ params });

    if (api) {
      api.then((r) => this.afterRetrieve(r));
    }

    return this;
  }

  afterRetrieve(r) {
    this.setCollectionData(r.getData());
  }

  canDisplay(column) {
    const component = this;
    return !column.canDisplay || column.canDisplay({ component, column });
  }

  isBordered() {
    return this.bordered;
  }
}

export { Collection };
export default new Collection();
