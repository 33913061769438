const ExportDefault = (params) => {
    params = params || {};

    return {
        headerMainFooter: {
            containerPage: {
                height: '100vh',
                width: '100%'
            },
            container: {
                height: '100%',
                width: '100%'
            },
            main: {
                overflowX: 'hidden',
                overflowY: 'auto',
            },
            mainContainer: {
                width: '100%',
            }
        }
    }
};

export default ExportDefault;