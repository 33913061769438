import { Table } from '../../../../../../../Admin/Components/Widget/Collection/Table';
import DateRangeFilterRenderer from '../../../../../../../Admin/template/collection/render/dateRangeFilter';
import ActionRenderer from '../../../../../../../Admin/template/collection/render/action';
import AccountApi from '../../../../../../../Api/Account';

export default class AccountAppJobFailedTable extends Table {
    id = "account-app-job-failed-table";
    title = 'failed jobs';

    filters = [{
        id: 'path',
        label: 'path'
    },{
        id: 'created_at',
        labelFrom: 'created at (from)',
        labelTo: 'created at (to)',
        fitlerRender: DateRangeFilterRenderer
    }];

    columns = [{
        id: 'run',
        align: 'right',
        type: 'primary',
        text: 'run',
        click: (props) => {
            const { component, row } = props;

            if (window.confirm(component.ucfirst('are you sure?'))) {
                const account = this.getPage().getParam('account');
                const app = this.getPage().getParam('app');
                const { id } = row;
                
                AccountApi.job.failed.run(account,app,id).then(r => {
                    if (r.isValid()) {
                        component.retrieve();
                    }
                });
            }
        },
        render: ActionRenderer
    },{
        id: 'id',
        label: 'id',
        align: 'left',
        bind: 'id',
        sortable: true, 
    },{
        id: 'path',
        label: 'name',
        align: 'left',
        bind: 'path',
        sortable: true,

    },{
        id: 'data',
        label: 'data',
        align: 'left',
        bind: 'data',

    },{
        id: 'created_at',
        label: 'created at',
        align: 'right',
        bind: 'created_at',
        sortable: true,
    },{
        id: 'delete',
        align: 'right',
        type: 'secondary',
        text: 'delete',
        click: (props) => {
            const { component, row } = props;

            if (window.confirm(component.ucfirst('are you sure?'))) {
                const { account, app } = this.getPage().getParams();
                const { id } = row;
                
                AccountApi.job.failed.delete(account,app,id).then(r => {
                    if (r.isValid()) {
                        component.retrieve();
                    }
                });
            }
        },
        render: ActionRenderer
    }];

    getApi(options) {
        const { account, app } = this.getPage().getParams();
        return AccountApi.job.failed.collection(account,app,options);
    }

    onLoad() {
        super.onLoad();
        this.retrieve();
    }

    afterRetrieve(r) {
        if (r.isValid()) {
            this.setCollectionData(r.getData());
        } else {
            //this.getPage().redirect('/accounts');
        }
    }
}