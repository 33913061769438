import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ErrorMessage from "../messages/error";
import SuccessMessage from "../messages/success";

const UseStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
  },
  action: {
    margin: theme.spacing(2, 0),
  },
}));

const ExportDefault = (props) => {
  const { component } = props;
  const id = component.getId();
  const classes = UseStyles();

  return (
    <form
      id={id}
      autoComplete="off"
      className={classes.form}
      onSubmit={(e) => component.submit({ e })}
    >
      <Box>
        <SuccessMessage {...props} />
        <ErrorMessage {...props} />
      </Box>
      {component.getFields().map((field) => {
        const key = `${id}-${field.id}`;
        const Render = field.render;
        const form = component;
        const props = { ...{ form }, ...field };
        const holder = field.holder;
        const display = field.canDisplay ? field.canDisplay(props) : true;

        return (
          display && (
            <Box className={classes[holder]} key={key}>
              {Render && <Render {...props} />}
            </Box>
          )
        );
      })}
    </form>
  );
};

export default ExportDefault;
