import { Form } from "../Form";

class Crud extends Form {
  form = "";
  modal = "";
  collection = "";

  state = {
    data: {
      default: {
        state: "create",
      },
    },
  };

  submit(options) {
    super.submit(options);

    if (this.isOnCreate()) {
      this.create();
    } else if (this.isOnUpdate()) {
      this.update();
    }
  }

  isOnCreate() {
    return this.dataEqualsTo("default.state", "create");
  }

  isOnUpdate() {
    return this.dataEqualsTo("default.state", "update");
  }

  getApi(options, type) {
    //
  }

  create() {
    if (this.validate()) {
      const api = this.getApi({}, "create");

      if (api) {
        api.then((r) => this.afterCreate(r));
      }
    } else {
      const mainMessage = this.getComponents().findById("main-message");

      if (mainMessage) {
        mainMessage
          .first()
          .setMessage("validation-error")
          .setSeverity("error")
          .open();
      } else {
        this.setData({
          "error.message": "validation-error",
        });
      }
    }

    return this;
  }

  update() {
    if (this.validate()) {
      const api = this.getApi({}, "update");

      if (api) {
        api.then((r) => this.afterUpdate(r));
      }
    } else {
      const mainMessage = this.getComponents().findById("main-message");

      if (mainMessage) {
        mainMessage
          .first()
          .setMessage("validation-error")
          .setSeverity("error")
          .open();
      } else {
        this.setData({
          "error.message": "validation-error",
        });
      }
    }

    return this;
  }

  retrieve() {
    const api = this.getApi({}, "retrieve");

    if (api) {
      api.then((r) => this.afterRetrieve(r));
    }

    return this;
  }

  afterRetrieve(r) {
    if (r.isValid()) {
      this.setData({ "default.fields": r.getData() });
    }
  }

  afterCreate(r) {
    if (r.isValid()) {
      this.deleteData("default.fields");
      this.closeModal().retrieveCollection();
    } else {
      const mainMessage = this.getComponents().findById("main-message");
      const message = r.getMessage();

      if (mainMessage) {
        mainMessage.first().setMessage(message).setSeverity("error").open();
      } else {
        this.setData({
          "error.message": message,
        });
      }
    }
  }

  afterUpdate(r) {
    let errorMessage = "";
    let successMessage = "";
    const valid = r.isValid();

    if (valid) {
      const components = this.getComponents();
      successMessage = r.getMessage();

      this.retrieveCollection();

      if (this.form) {
        components.findById(this.form).forEach((c) => c.retrieve());
      }
    } else {
      errorMessage = r.getMessage();
    }

    if (successMessage || errorMessage) {
      const mainMessage = this.getComponents().findById("main-message");

      if (mainMessage) {
        mainMessage
          .first()
          .setMessage(r.getMessage())
          .setSeverity(valid ? "success" : "error")
          .open();
      } else {
        this.setData({
          "success.message": successMessage,
          "error.message": errorMessage,
        });
      }
    }
  }

  closeModal() {
    if (this.modal) {
      this.getComponents()
        .findById(this.modal)
        .forEach((c) => c.close());
    }

    return this;
  }

  retrieveCollection() {
    if (this.collection) {
      this.getComponents()
        .findById(this.collection)
        .forEach((c) => c.retrieve());
    }

    return this;
  }
}

export { Crud };
export default new Crud();
