import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from '../../../styles/themes/theme1';

const pageTheme = theme();
const UseStyles = makeStyles({
  backdrop: {
    
    zIndex: pageTheme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const ExportDefault = (props) => {
    const { component } = props;
    const classes = UseStyles();

    return (
        <Backdrop className={classes.backdrop} open={component.dataEqualsTo('loading',true)}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default ExportDefault;
