import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AccountAppsTable from '../../../../Components/Collection/Account/Apps';
import WidgetsStyles from '../../../../styles/widgets';

const UseStyles = makeStyles(WidgetsStyles().panel);

const ExportDefault = (props) => {
    //const { component } = props;
    const classes = UseStyles();
    
    return (
        <Box className={classes.main}>
            <AccountAppsTable />
        </Box>
    );
}

export default ExportDefault;