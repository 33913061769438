import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Divider from "@material-ui/core/Divider";
import Image from "../../../../../Core/templates/widget/image";
import SystemApi from "../../../../../Api/System";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    textTransform: "capitalize",
  },
  menuItemApp: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
}));

const ExportDefault = function PrimarySearchAppBar(props) {
  const { component } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { account } = component.getPage().getParams();
  const isMenuOpen = Boolean(anchorEl);
  const title = component.getPage().getTitle();
  const username = component.getHelpers("auth").getUserAttribute("username");
  const apps = component.getData("default.apps", []);
  const urlHelper = component.getHelpers("url");
  const hasMainMemu = component.props.withMainMenu;
  const load = "/images/loading.png";
  const error = "/images/not-found.png";
  const defaultAccount = component
    .getHelpers("env")
    .get("default_account", "automatapp");

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose(null);
    component.getPage().logout();
  };

  const handleCacheClear = (event) => {
    if (window.confirm(component.ucfirst("are you sure?"))) {
      const name = component.getHelpers("env").get("name");
      SystemApi.cache
        .clear(account, name)
        .then((r) => alert(component.ucfirst(r.getMessage())));
    }
  };

  // const handlePurge = (event) => {
  //   if (window.confirm(component.ucfirst("are you sure?"))) {
  //     SystemApi.cache
  //       .purge(account)
  //       .then((r) => alert(component.ucfirst(r.getMessage())));
  //   }
  // };

  // const handleTest = (event) => {
  //   SystemApi.test
  //     .test(account)
  //     .then((r) => {
  //       component.getMainMessage((c) =>
  //         c.setSeverity("success").setMessage(r.getData()).open()
  //       );
  //     })
  //     .catch((err) => {
  //       //console.log(err);
  //     });
  // };

  const renderMenu = (
    <Menu
      id="top-bar-profile-menu"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} className={classes.menuItemApp}>
        {username}
      </MenuItem>
      <Divider />
      {apps.map((app) => {
        const src = urlHelper.createAccountImageUrl(
          defaultAccount,
          app.slug,
          "account",
          app.slug
        );

        return (
          app.url && (
            <MenuItem
              key={`${component.getId()}-menu-app-${app.slug}`}
              className={classes.menuItemApp}
              component="a"
              href={app.url}
            >
              <Image
                buildSrc={() => src}
                block={true}
                buildLoad={() => load}
                buildError={() => error}
                className={classes.smallAvatar}
              />{" "}
              <span>{component.ucfirst(`${app.name}`)}</span>
            </MenuItem>
          )
        );
      })}
      {account && (
        <Box component="div">
          <Divider />
          <MenuItem onClick={handleCacheClear} className={classes.menuItemApp}>
            {component.ucfirst("clear")}
          </MenuItem>
        </Box>
      )}
      {/* {account && (
        <Box component="div">
          <Divider />
          <MenuItem onClick={handlePurge} className={classes.menuItemApp}>
            {component.ucfirst("purge")}
          </MenuItem>
        </Box>
      )}
      {account && (
        <Box component="div">
          <Divider />
          <MenuItem onClick={handleTest} className={classes.menuItemApp}>
            {component.ucfirst("test")}
          </MenuItem>
        </Box>
      )} */}
      <Divider />
      <MenuItem onClick={handleLogout} className={classes.menuItemApp}>
        {component.ucfirst("logout")}
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
          {hasMainMemu && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                const mainMenu = component
                  .getComponents()
                  .findById("main-menu")
                  .first();

                if (mainMenu) {
                  mainMenu.setData({ "default.opened": true });
                }
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography className={classes.title} variant="h6" noWrap>
            {title}
          </Typography>
          <div className={classes.grow} />
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls="top-bar-profile-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
};

export default ExportDefault;
