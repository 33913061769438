import Api from "../Core/Helpers/Api";

const systemApi = Api.create("system");

const ExportDefault = {
  account: {
    collection: function (options) {
      return systemApi.get(`account/collection`, options);
    },
    create: function (options) {
      return systemApi.post(`account`, options);
    },
  },
  /*
    one: function(account, options) {
      return systemApi.get(`account/${account}`,options);
    },
    update: function(account, options) {
      return systemApi.put(`account/${account}`,options);
    },
    user: {
      collection: function(account, options) {
        return systemApi.get(`account/${account}/user/collection`,options);
      },
      create: function(account, options) {
        return systemApi.post(`account/${account}/user`,options);
      },
      one: function(account, id, options) {
        return systemApi.get(`account/${account}/user/${id}`,options);
      },
      update: function(account, id, options) {
        return systemApi.put(`account/${account}/user/${id}`,options);
      },
      delete: function(account, id, options) {
        return systemApi.delete(`account/${account}/user/${id}`,options);
      },
      app: {
        collection: function(account, id, options) {
          return systemApi.get(`account/${account}/user/${id}/app/collection`,options);
        },
        create: function(account, id, options) {
          return systemApi.post(`account/${account}/user/${id}/app`,options);
        },
        one: function(account, id, options) {
          return systemApi.get(`account/${account}/user/app/${id}`,options);
        },
        delete: function(account, id, options) {
          return systemApi.delete(`account/${account}/user/app/${id}`,options);
        },
        action: {
          update: function(account, id, options) {
            return systemApi.put(`account/${account}/user/app/${id}/action`,options);
          },
          collection: function(account, app, options) {
            return systemApi.get(`account/${account}/user/app/${app}/action/collection`,options);
          },
        },
      }
    },
    app: {
      collection: function(account, options) {
        return systemApi.get(`account/${account}/app/collection`,options);
      },
      one: function(account, app, options) {
        return systemApi.get(`account/${account}/app/${app}`,options);
      },
    }
  },
  value: {
    collection: function(account, app, options) {
        return systemApi.get(`account/${account}/app/${app}/value/collection`,options);
    },
    create: function(account, app, options) {
      return systemApi.post(`account/${account}/app/${app}/value`,options);
    },
    one: function(account, app, id, options) {
      return systemApi.get(`account/${account}/app/${app}/value/${id}`,options);
    },
    update: function(account, app, id, options) {
      return systemApi.put(`account/${account}/app/${app}/value/${id}`,options);
    },
    delete: function(account, app, id, options) {
      return systemApi.delete(`account/${account}/app/${app}/value/${id}`,options);
    },
  },
  language: {
    collection: function(account, app, options) {
        return systemApi.get(`account/${account}/app/${app}/language/collection`,options);
    },
    create: function(account, app, options) {
      return systemApi.post(`account/${account}/app/${app}/language`,options);
    },
    one: function(account, app, id, options) {
      return systemApi.get(`account/${account}/app/${app}/language/${id}`,options);
    },
    update: function(account, app, id, options) {
      return systemApi.put(`account/${account}/app/${app}/language/${id}`,options);
    },
    delete: function(account, app, id, options) {
      return systemApi.delete(`account/${account}/app/${app}/language/${id}`,options);
    },
  },
  literal: {
    collection: function(account, app, options) {
        return systemApi.get(`account/${account}/app/${app}/literal/collection`,options);
    },
    create: function(account, app, options) {
      return systemApi.post(`account/${account}/app/${app}/literal`,options);
    },
    one: function(account, app, id, options) {
      return systemApi.get(`account/${account}/app/${app}/literal/${id}`,options);
    },
    update: function(account, app, id, options) {
      return systemApi.put(`account/${account}/app/${app}/literal/${id}`,options);
    },
    delete: function(account, app, id, options) {
      return systemApi.delete(`account/${account}/app/${app}/literal/${id}`,options);
    },
  },
  */
  translation: {
    collection: function (account, app, options) {
      return systemApi.get(
        `account/${account}/app/${app}/translation/collection`,
        options
      );
    },
    create: function (account, app, options) {
      return systemApi.post(
        `account/${account}/app/${app}/translation`,
        options
      );
    },
    one: function (account, app, id, options) {
      return systemApi.get(
        `account/${account}/app/${app}/translation/${id}`,
        options
      );
    },
    update: function (account, app, id, options) {
      return systemApi.put(
        `account/${account}/app/${app}/translation/${id}`,
        options
      );
    },
    delete: function (account, app, id, options) {
      return systemApi.delete(
        `account/${account}/app/${app}/translation/${id}`,
        options
      );
    },
  },
  /*
  log: {
    collection: function(account, app, options) {
        return systemApi.get(`account/${account}/app/${app}/log/collection`,options);
    },
    delete: function(account, app, id, options) {
      return systemApi.delete(`account/${account}/app/${app}/log/${id}`,options);
    },
  },
  job: {
    failed: {
      collection: function(account, app, options) {
        return systemApi.get(`account/${account}/app/${app}/job/failed/collection`,options);
      },
      run: function(account, app, id, options) {
        return systemApi.post(`account/${account}/app/${app}/job/failed/${id}/run`,options);
      },
      delete: function(account, app, id, options) {
        return systemApi.delete(`account/${account}/app/${app}/job/failed/${id}`,options);
      },
    }
  },
  */
  cache: {
    clear: function (account, app, options) {
      return systemApi.put(
        `account/${account}/app/${app}/cache/clear`,
        options
      );
    },
    purge: function (account, options) {
      return systemApi.put(`account/${account}/cache/purge`, options);
    },
  },
  batch: {
    create: function (account, app, options) {
      return systemApi.post(
        `account/${account}/app/${app}/batch/create`,
        options
      );
    },
  },
  test: {
    test: function (account, options) {
      return systemApi.get(`account/${account}/test/test`, options);
    },
  },
  user: {
    collection: function (options) {
      return systemApi.get(`user/collection`, options);
    },
    create: function (options) {
      return systemApi.post(`user`, options);
    },
    one: function (id, options) {
      return systemApi.get(`user/${id}`, options);
    },
    update: function (id, options) {
      return systemApi.put(`user/${id}`, options);
    },
    delete: function (id, options) {
      return systemApi.delete(`user/${id}`, options);
    },
  },
  app: {
    collection: function (options) {
      return systemApi.get(`app/collection`, options);
    },
    create: function (options) {
      return systemApi.post(`app`, options);
    },
    one: function (id, options) {
      return systemApi.get(`app/${id}`, options);
    },
    update: function (id, options) {
      return systemApi.put(`app/${id}`, options);
    },
    delete: function (id, options) {
      return systemApi.delete(`app/${id}`, options);
    },
    action: {
      collection: function (options) {
        return systemApi.get(`app/action/collection`, options);
      },
      create: function (id, options) {
        return systemApi.post(`app/${id}/action`, options);
      },
      one: function (id, options) {
        return systemApi.get(`app/action/${id}`, options);
      },
      update: function (id, options) {
        return systemApi.put(`app/action/${id}`, options);
      },
      delete: function (id, options) {
        return systemApi.delete(`app/action/${id}`, options);
      },
    },
  },
  plan: {
    collection: function (options) {
      return systemApi.get(`plan/collection`, options);
    },
    create: function (options) {
      return systemApi.post(`plan`, options);
    },
    one: function (id, options) {
      return systemApi.get(`plan/${id}`, options);
    },
    update: function (id, options) {
      return systemApi.put(`plan/${id}`, options);
    },
    delete: function (id, options) {
      return systemApi.delete(`plan/${id}`, options);
    },
    app: {
      collection: function (options) {
        return systemApi.get(`plan/app/collection`, options);
      },
      create: function (id, options) {
        return systemApi.post(`plan/${id}/app`, options);
      },
      one: function (id, options) {
        return systemApi.get(`plan/app/${id}`, options);
      },
      update: function (id, options) {
        return systemApi.put(`plan/app/${id}`, options);
      },
      delete: function (id, options) {
        return systemApi.delete(`plan/app/${id}`, options);
      },
    },
  },
};

export default ExportDefault;
