import { Component } from "../Component";

class Form extends Component {
  fields = [];

  getFields() {
    return this.fields || [];
  }

  submit(options, type) {
    this.preventDefault(options);
  }
}

export { Form };
export default new Form();
