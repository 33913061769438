import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Styles from '../../styles/layout';

const UserStyles = makeStyles(Styles().headerMainFooter);

const exportDefault = (props) => {
    const { component } = props;
    const componentProps = component.props;
    const { renderHeader, renderMain, renderFooter, } = componentProps;
    const classes = UserStyles();
    let { page, paddingTop, paddingBottom, padding } = componentProps;

    page = page ? classes.containerPage: classes.container;
    paddingTop = `${paddingTop || 0}px`;
    paddingBottom = `${paddingBottom || 0}px`;
    padding = `${padding || 0}px`;

    return (
      <Box display="flex" flexDirection="column" style={{paddingTop,paddingBottom}} className={page}>
          { 
            renderHeader && 
            <Box display="flex" flexShrink="1">{renderHeader()}</Box> 
          }
          { 
            renderMain && 
            <Box display="flex" className={classes.main} style={{padding}}>
                <Box className={classes.mainContainer}>{renderMain()}</Box>              
            </Box>
          }
          { 
            renderFooter && 
            <Box display="flex" flexShrink="1">{renderFooter()}</Box> 
          }
      </Box>
    );
  };

  export default exportDefault;