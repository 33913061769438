import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TopBar from "../../../../Components/Widget/TopBar";
import theme from "../../../../styles/themes/theme1";
import { Breadcrumbs } from "../../../../../../Admin/Components/Widget/Breadcrumbs";
import BottomBar from "../../../../Components/Widget/BottomBar";
import Tabs from "../../../../Components/Tabs/Account/App";
import HeaderMainFooterLayout from "../../../../../../Admin/Components/Layout/HeaderMainFooter";
import ValueModal from "../../../../Components/Modal/Account/App/Value";
import LanguageModal from "../../../../Components/Modal/Account/App/Language";
import LiteralModal from "../../../../Components/Modal/Account/App/Literal";
import PackageModal from "../../../../Components/Modal/Account/App/Package";
import TranslationModal from "../../../../../../Admin/Components/Widget/Translation/Modal";
import PageLoader from "../../../../Components/Widget/PageLoader";
import FilesModal from "../../../../../../Admin/Components/Widget/Files/Modal";

const pageTheme = theme();

const PageBreadcrumbs = () => {
  return (
    <Breadcrumbs
      build={(component) => {
        return [
          {
            type: "link",
            label: component.ucfirst("accounts"),
            to: "/accounts",
          },
          {
            type: "link",
            label: component.getData("default.account.slug", ""),
            to: `/account/${component.getData("default.account.slug", "")}`,
          },
          {
            type: "text",
            label: component.getData("default.app.slug", ""),
          },
        ];
      }}
    />
  );
};

const ExportDefault = function (props) {
  return (
    <ThemeProvider theme={pageTheme}>
      <PageLoader />
      <TopBar />
      <TranslationModal />
      <ValueModal />
      <LanguageModal />
      <LiteralModal />
      <PackageModal />
      <FilesModal />
      <Container maxWidth="xl">
        <HeaderMainFooterLayout
          page={true}
          paddingTop={80}
          paddingBottom={70}
          renderHeader={() => <PageBreadcrumbs />}
          renderMain={() => <Tabs />}
        />
      </Container>
      <BottomBar />
    </ThemeProvider>
  );
};

export default ExportDefault;
