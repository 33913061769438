import { Modal } from "../Modal";
import template from "../../../template/widget/files/uploader";
import FileApi from "../../../../Api/File";

export default class FilesUploader extends Modal {
  id = "files-uploader";
  size = "md";
  template = template;

  uploadFiles(files) {
    const uploadings = [];

    for (let i in files) {
      uploadings.push(this.uploadFile(files[i]));
    }

    Promise.all(uploadings).then(() => {
      this.close();
      this.getComponents()
        .findById("files-table")
        .forEach((c) => c.retrieve());
    });
  }

  uploadFile(file) {
    const formData = new FormData();
    const { account, app, entity, entity_slug, entity_name } = this.getData(
      "params",
      {}
    );
    const options = {
      headers: {
        "Content-type": "application/json",
      },
      onUploadProgress: function (progressEvent) {
        //const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //console.log(percentCompleted);
      },
    };

    formData.append("file", file);
    formData.append("entity", entity);
    formData.append("entity_slug", entity_slug);
    formData.append("entity_name", entity_name);

    return FileApi.file.create(account, app, formData, options);
  }

  getFilesLimit() {
    return (
      this.getComponents()
        .findById("files-modal")
        .first()
        .getData("default.values.files-limit.data", 10) * 1
    );
  }

  getMaxFilesSize() {
    return (
      this.getComponents()
        .findById("files-modal")
        .first()
        .getData("default.values.max-file-size.data", 8000000) * 1
    );
  }

  getAcceptedFiles() {
    return this.getComponents()
      .findById("files-modal")
      .first()
      .getData(
        "default.values.accepted-files.data",
        "image/*,application/pdf,application/zip,text/csv"
      )
      .split(",");
  }
}
