import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextFilterRenderer from "../../../collection/render/textFilter";

const UseFiltersStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  filter: {
    marginBottom: theme.spacing(2),
  },
}));

const ExportDefault = (props) => {
  const { component } = props;
  const collection = component.getCollection();
  const classes = UseFiltersStyles();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        component.filter();
      }}
    >
      {collection.getFilters().map((column) => {
        const { id } = column;
        const key = `${collection.getId()}-filter-${id}`;
        const props = { component, collection, column };
        const Render = column.fitlerRender || TextFilterRenderer;

        return (
          !component.isViewable(id) && (
            <Box key={key} className={classes.filter}>
              <Render {...props} />
            </Box>
          )
        );
      })}
      <button hidden></button>
    </form>
  );
};

export default ExportDefault;
