import { Table } from "../../../../../../Admin/Components/Widget/Collection/Table";
import Account from "../../../../../../Api/Account";
import DateRangeFilterRenderer from "../../../../../../Admin/template/collection/render/dateRangeFilter";
import ActionRenderer from "../../../../../../Admin/template/collection/render/action";
import AccountApi from "../../../../../../Api/Account";

export default class AccountUserAppsTable extends Table {
  id = "account-user-apps-table";
  title = "apps";

  filters = [
    {
      id: "slug",
      label: "slug",
    },
    {
      id: "created_at",
      labelFrom: "created at (from)",
      labelTo: "created at (to)",
      fitlerRender: DateRangeFilterRenderer,
    },
  ];

  columns = [
    {
      id: "id",
      label: "id",
      align: "left",
      bind: "id",
      sortable: true,
    },
    {
      id: "slug",
      label: "slug",
      align: "left",
      bind: "app_slug",
      sortable: true,
    },
    {
      id: "created_at",
      label: "created at",
      align: "right",
      bind: "created_at",
      sortable: true,
    },
    {
      id: "delete",
      align: "right",
      type: "secondary",
      text: "delete",
      hidden: (props) => props.row.owner === 1,
      click: (props) => {
        const { component, row } = props;

        if (window.confirm(component.ucfirst("are you sure?"))) {
          const { account } = this.getPage().getParams();
          const { id } = row;

          AccountApi.account.user.app.delete(account, id).then((r) => {
            if (r.isValid()) {
              component.retrieve();
            }
          });
        }
      },
      canDisplay: (props) => {
        const { account } = this.getPage().getParams();
        return props.component.getHelpers("auth").isAdmin(account);
      },
      render: ActionRenderer,
    },
    /*,{
        id: 'actions',
        align: 'right',
        text: 'actions',
        click: (props) => {
            const { component, row } = props;
            const { id, app_slug } = row;
            const { account } = component.getPage().getParams();

            component
                .getComponents()
                .findById('user-app-actions-modal')
                .forEach(c => c.setData({'params':{
                    id,
                    account,
                    appSlug: app_slug
                }}).open())
            ;
        },
        canDisplay: (props) => {
            const { account } = this.getPage().getParams();
            return props.component.getHelpers('auth').isAdmin(account)
        },
        render: ActionRenderer
    }
    */
  ];

  getApi(options) {
    const { account, id } = this.getPage().getParams();
    return Account.account.user.app.collection(account, id, options);
  }

  onLoad() {
    super.onLoad();
    this.retrieve();
  }

  afterRetrieve(r) {
    if (r.isValid()) {
      this.setCollectionData(r.getData());
    } else {
      //this.getPage().redirect(`/accounts`);
    }
  }
}
