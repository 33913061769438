import { Modal } from '../../../../../../../Admin/Components/Widget/Modal';
import templateContent from '../../../../../templates/default/panel/account/user/app/actions';
import AccountApi from '../../../../../../../Api/Account';

export default class UserAppActionsModal extends Modal {
    id = 'user-app-actions-modal';
    title = 'actions';
    size = 'md';

    templateContent = templateContent;

    onBeforeOpen() {
        const { id, account, appSlug } = this.getData('params');

        Promise.all([
            AccountApi.account.user.app.action.collection(account,appSlug),
            AccountApi.account.user.app.one(account,id),
        ]).then(([appActions, userActions]) => {
            this.setData({'default.message':'','default.entities':[],'default.selected':{}});
            this.createEntities(appActions.getData().rows);
            this.createUserActions(userActions.getData());
        });
    }

    createEntities(data) {
        const entitiesActions = this.buildEntitiesActions(data);
        const entities = [];

        for (let i in entitiesActions) {
            const _entitiesActions = entitiesActions[i];
            _entitiesActions.actions.sort((action1, action2) => action1.position - action2.position);
            entities.push(_entitiesActions);
        }

        this.setData({'default.entities':entities});
    }

    buildEntitiesActions(data) {
        const entities = [];

        data.forEach(data => {
            const { entity, slug, name, position } = data;

            if (!entities[entity]) {
                entities[entity] = {
                    slug: entity,
                    actions: []
                };
            }

            entities[entity].actions.push({
                slug,
                name,
                position,
                checked: false
            });
        });

        return entities;
    }

    createUserActions(data) {
        const { actions } = data || {};
        this.setData({'default.selected':actions ? JSON.parse(actions): {}});
    }

    isActionChecked(entity, action) {
        return this.getData(`default.selected.${entity}.${action}`,false);
    }

    toggleAction(entity, action) {
        const selected = this.getData('default.selected',{});

        if (!selected[entity]) {
            selected[entity] = {};
        }

        if (selected[entity][action]) {
            delete selected[entity][action];
        } else {
            selected[entity][action] = true;
        }

        if (!this.getHelpers('dev').getObjectLength(selected[entity])) {
            delete selected[entity];
        }

        this.setData({'default.selected':selected});

        return this;
    }

    submitActions() {
        const { id, account } = this.getData('params');
        let actions = this.getData('default.selected',{});
        actions = this.getHelpers('dev').getObjectLength(actions) ? actions: '';

        AccountApi.account.user.app.action.update(account,id,{actions}).then(r => {
            this.setData({'default.message':r.getMessage()});
        });        
    }
}