import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Pages from "../Components/Pages";
import { MainMessage } from "../../../Admin/Components/Widget/MainMessage";

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <Router>
        <CssBaseline />
        <Switch>
          <Route exact path="/login" component={Pages.Login} />
          <Route exact path="/register" component={Pages.Register} />
          <Route exact path="/" component={Pages.Accounts} />
          <Route exact path="/home" component={Pages.Accounts} />
          <Route exact path="/accounts" component={Pages.Accounts} />
          <Route exact path="/account/:account" component={Pages.Account} />
          <Route
            exact
            path="/account/:account/user/:id"
            component={Pages.AccountUser}
          />
          <Route
            exact
            path="/account/:account/app/:app"
            component={Pages.AccountApp}
          />
        </Switch>
        <MainMessage />
      </Router>
    )
  );
};

export default ExportDefault;
