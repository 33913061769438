import { Table } from '../../../../../../Admin/Components/Widget/Collection/Table';
import DateRangeFilterRenderer from '../../../../../../Admin/template/collection/render/dateRangeFilter';
import ActionRenderer from '../../../../../../Admin/template/collection/render/action';
import AccountApi from '../../../../../../Api/Account';

export default class AccountAppLogTable extends Table {
    id = "account-app-log-table";
    title = 'log';

    filters = [{
        id: 'type',
        label: 'type'
    },{
        id: 'name',
        label: 'name'
    },{
        id: 'created_at',
        labelFrom: 'created at (from)',
        labelTo: 'created at (to)',
        fitlerRender: DateRangeFilterRenderer
    }];

    columns = [{
        id: 'id',
        label: 'id',
        align: 'left',
        bind: 'id',
        sortable: true, 
    },{
        id: 'type',
        label: 'type',
        align: 'left',
        bind: 'type',
        sortable: true,

    },{
        id: 'name',
        label: 'name',
        align: 'left',
        bind: 'name',
        sortable: true,

    },{
        id: 'data',
        label: 'data',
        align: 'left',
        bind: 'data',
        sortable: true,

    },{
        id: 'created_at',
        label: 'created at',
        align: 'right',
        bind: 'created_at',
        sortable: true,
    },{
        id: 'delete',
        align: 'right',
        type: 'secondary',
        text: 'delete',
        click: (props) => {
            const { component, row } = props;

            if (window.confirm(component.ucfirst('are you sure?'))) {
                const { account, app } = component.getPage().getParams();
                const { id } = row;
                
                AccountApi.log.delete(account,app,id).then(r => {
                    if (r.isValid()) {
                        component.retrieve();
                    }
                });
            }
        },
        render: ActionRenderer
    }];

    getApi(options) {
        const { account, app } = this.getPage().getParams();
        return AccountApi.log.collection(account,app,options);
    }

    onLoad() {
        super.onLoad();
        this.retrieve();
    }

    afterRetrieve(r) {
        if (r.isValid()) {
            this.setCollectionData(r.getData());
        } else {
            //this.getPage().redirect('/accounts');
        }
    }
}