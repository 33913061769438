import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const ExportDefault = (props) => {
    const { component, column } = props;
    const { text, buildContent, buildHref } = column;
    const content = buildContent ? buildContent(props): component.trans(text || 'edit');

    return (        
        <Link component={RouterLink} to={buildHref ? buildHref(props): ''}>
            {content}
        </Link>
    );
};

export default ExportDefault;