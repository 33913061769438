import Page from "../../../../../Core/Components/Page";
import templateDefault from "../../../templates/default/pages/account/app";

export default class AccountApp extends Page {
  title = "account app";
  fullAuth = true;

  template = templateDefault;

  setAmount(amount) {
    this.setData({
      "default.amount": amount,
    });
  }
}
