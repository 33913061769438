import { Modal } from '../../../../../Admin/Components/Widget/Modal';
import templateContent from '../../Form/Account/NewUser';

export default class NewUserModal extends Modal {
    id = 'new-user-modal';
    title = 'new user';
    templateContent = templateContent;

    onBeforeOpen() {
        this.getComponents().findById('new-user-form').forEach(c => c.setData({'default.fields':{},'error':{}}));
    }
}