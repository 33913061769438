import { Crud } from '../../../../../../Core/Components/Form/Crud';
import templateDefault from '../../../../../../Admin/template/form/type/normal';
import SelectField from '../../../../../../Admin/template/form/fields/type/select';
import ActionField from '../../../../../../Admin/template/form/fields/type/action';
import AccountApi from '../../../../../../Api/Account';

export default class NewAppForm extends Crud {
    template = templateDefault;

    id = 'new-app-form';
    modal = 'new-app-modal';
    collection = 'account-user-apps-table';

    fields = [{
        'id': 'select-app',
        'path': 'planAppId',
        'label': 'select app',
        'optionsPath': 'apps',
        'fullWidth': true,
        'getValue': (props) => props.option.id,
        'getLabel': (props) => props.option.slug,
        'render': SelectField,
    },{
        'id': 'create',
        'label': 'create',
        'holder': 'action',
        'fullWidth': true,
        'render': ActionField
    }];

    onLoad() {
        super.onLoad();
        this.deleteData('error').retrieveApps();
    }

    retrieveApps() {
        const { account } = this.getPage().getParams();
        
        AccountApi.account.app.collection(account).then(r => {
            if (r.isValid()) {
                const { rows } = r.getData();
                this.setData({'default.apps':rows || []});
            }
        });
    }

    getApi(options, type) {
        const { account, id } = this.getPage().getParams();
        return AccountApi.account.user.app.create(account,id,this.getData('default.fields'));
    }

    onValidate() {
        return {
            'fields.planAppId': [{
                    'rule': 'required',
                }],
        };
    }
}