import { Component } from '../../../../Core/Component';
import templateDefault from '../../../template/widget/files/preview';
import FileApi from '../../../../Api/File';

export default class FilePreview extends Component {
    id = "files-preview";
    
    template = templateDefault;

    open(data) {
        const { account, app, entity, id } = data;

        this.setData({'default.open':true});

        FileApi.file.display(account, app, id,{params:{entity}}).then(r => {
            this.setFile(r.getData());
        });
    }

    setFile(file) {
        this.setData({'default.file':file});
        return this;
    }

    getFile() {
        return this.getData('default.file',{}) || {};
    }

    removeFile() {
        this.setData({'default.open':false,'default.file':null});
        return this;
    }

    getSrcData() {
        const { type = null, data = null } = this.getFile();
        return type && data ? `data:${type};base64,${data}`: null;
    }

    close() {
        return this.removeFile();
    }

    isImage() {
        const { type = null } = this.getFile();
        return type && type.startsWith('image/');
    }
}
