import { Collection } from "../../../../Core/Components/Collection";
import templateDefault from "../../../template/collection/table";

class Table extends Collection {
  template = templateDefault;

  // export = false;
  // delete = false;

  // setExport(isexport) {
  //   this.export = isexport;

  //   return this;
  // }

  // setDelete(isdelete) {
  //   this.delete = isdelete;

  //   return this;
  // }

  export(plain) {
    const params = this.createRetrieveParams();
    params.export = true;
    params.exportPlain = plain ? true : false;
    const api = this.getApi({ params });

    if (api) {
      api.then((r) => this.afterExport(r));
    }

    return this;
  }

  afterExport(r) {
    const rData = r.getData();
    const { data, type, name } = rData;
    const download = () => {
      let a = document.createElement("a");
      a.href = `data:${type}:,${encodeURIComponent(data)}`;
      a.setAttribute("download", name);
      a.click();
    };
    download();
    // this.setExport(false);
  }

  delete() {
    const params = this.createRetrieveParams();
    params.delete = true;
    const api = this.getApi({ params });

    if (api) {
      api.then((r) => this.afterDelete(r));
    }

    return this;
  }

  afterDelete(r) {
    this.retrieve();
  }
}

export { Table };
export default new Table();
