import Api from "../Core/Helpers/Api";

const fileApi = Api.create("file");

const ExportDefault = {
  file: {
    collection: function (account, app, options) {
      return fileApi.get(
        `account/${account}/app/${app}/file/collection`,
        options
      );
    },
    create: function (account, app, file, options) {
      return fileApi.post(`account/${account}/app/${app}/file`, file, options);
    },
    one: function (account, app, id, options) {
      return fileApi.get(`account/${account}/app/${app}/file/${id}`, options);
    },
    update: function (account, app, id, options) {
      return fileApi.put(`account/${account}/app/${app}/file/${id}`, options);
    },
    delete: function (account, app, id, options) {
      return fileApi.delete(
        `account/${account}/app/${app}/file/${id}`,
        options
      );
    },
    display: function (account, app, id, options) {
      return fileApi.get(
        `account/${account}/app/${app}/file/${id}/display`,
        options
      );
    },
    entity: {
      display: function (account, app, slug, options) {
        return fileApi.get(
          `account/${account}/app/${app}/file/entity/${slug}/display`,
          options
        );
      },
    },
  },
};

export default ExportDefault;
