import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ErrorField from "../error";

const ExportDefault = (props) => {
  const { form } = props;

  let {
    id,
    path,
    dataForm = form,
    optionsPath,
    getValue,
    getLabel,
    multiple,
    renderValue,
    label,
    fullWidth,
    required,
    variant,
    margin,
    disabled,
    onChange,
  } = props;

  const renderSelectedValue =
    renderValue &&
    ((value) => {
      return renderValue(value, props);
    });

  margin = margin ? margin : "normal";
  variant = variant ? variant : "outlined";
  fullWidth = fullWidth ? true : false;
  multiple = multiple ? true : false;
  disabled = disabled ? true : false;
  id = form.getId() + "-" + id;
  let value = dataForm.getData(`default.fields.${path}`, multiple ? [] : "");

  const options = dataForm.getData(`default.${optionsPath}`, []);
  const onChangeValue = (e) => {
    const value = e.target.value;
    let selectedOption = null;

    options.every((option) => {
      if (value === getValue({ option })) {
        selectedOption = option;
        return false;
      }

      return true;
    });

    dataForm.setData(
      {
        [`default.fields.${path}`]: value,
        [`default.${optionsPath}_selected`]: selectedOption,
      },
      {
        cb: () => {
          if (onChange) {
            onChange(value, selectedOption, props);
          }
        },
      }
    );
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      error={dataForm.dataExists(`error.fields.${path}`)}
    >
      <InputLabel id={`${id}-label`}>{form.ucfirst(label)}</InputLabel>
      <Select
        labelId={`${id}-label`}
        multiple={multiple}
        disabled={disabled}
        label={form.ucfirst(label) + `${required ? " *" : ""}`}
        value={value}
        renderValue={renderSelectedValue}
        onChange={onChangeValue}
        error={dataForm.dataExists(`error.fields.${path}`)}
      >
        {options.map((option) => {
          const optionProps = { ...{ option }, ...props };
          const optionValue = getValue && getValue(optionProps);
          const optionLabel = getLabel && getLabel(optionProps);
          return (
            optionValue &&
            optionLabel && (
              <MenuItem key={`${id}-option-${optionValue}`} value={optionValue}>
                {optionLabel}
              </MenuItem>
            )
          );
        })}
      </Select>
      <ErrorField component={dataForm} name={path} />
    </FormControl>
  );
};

export default ExportDefault;
