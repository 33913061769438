import { Crud } from "../../../../../Core/Components/Form/Crud";
import templateDefault from "../../../../template/form/type/normal";
import TextField from "../../../../template/form/fields/type/text";
import SelectField from "../../../../template/form/fields/type/select";
import SwitchField from "../../../../template/form/fields/type/switch";
import ActionField from "../../../../template/form/fields/type/action";
import SystemApi from "../../../../../Api/System";
import AccountApi from "../../../../../Api/Account";
//import ContentApi from "../../../../../Api/Content";

export default class TranslationFormModal extends Crud {
  template = templateDefault;

  id = "translation-form-form";
  modal = "translation-form-modal";
  collection = "translation-table";

  fields = [
    {
      id: "select-language",
      path: "lang",
      label: "select language",
      optionsPath: "languages",
      fullWidth: true,
      getValue: (props) => {
        const { option } = props;
        return option.slug;
      },
      getLabel: (props) => {
        const { form, option } = props;
        return form.ucfirst(option.name);
      },
      render: SelectField,
    },
    {
      id: "select-scope",
      path: "scope",
      label: "select scope",
      optionsPath: "scopes",
      fullWidth: true,
      getValue: (props) => {
        const { option } = props;
        return option;
      },
      getLabel: (props) => {
        const { form, option } = props;
        return form.ucfirst(option);
      },
      render: SelectField,
    },
    {
      id: "slug",
      path: "slug",
      label: "slug",
      fullWidth: true,
      render: TextField,
    },
    {
      id: "html",
      path: "html",
      label: "html",
      render: SwitchField,
    },
    {
      id: "translation",
      path: "translation",
      label: "translation",
      multiline: true,
      fullWidth: true,
      rows: 10,
      render: TextField,
    },
    {
      id: "position",
      path: "position",
      label: "position",
      fullWidth: true,
      render: TextField,
    },
    {
      id: "public",
      path: "public",
      label: "public",
      render: SwitchField,
    },
    {
      id: "action",
      holder: "action",
      fullWidth: true,
      label: (props) => (props.form.isOnUpdate() ? "update" : "create"),
      render: ActionField,
    },
  ];

  onLoad(data) {
    super.onLoad(data);

    this.retrieveLanguages();
    this.retrieveValues();

    if (this.isOnUpdate()) {
      this.retrieve();
    }
  }

  async retrieveLanguages() {
    //const { account, repository } = this.getData("params", {});
    //const languages = await ContentApi.language.collection(account, repository);
    const { account, app } = this.getData("params", {});
    const languages = await AccountApi.language.collection(account, app);

    if (languages.isValid()) {
      const { rows = [] } = languages.getData();
      this.setData({ "default.languages": rows || [] });
    }
  }

  async retrieveValues() {
    //const { account, repository } = this.getData("params", {});
    //const values = await ContentApi.value.collection(account, repository);
    const { account, app } = this.getData("params", {});
    const values = await AccountApi.value.collection(account, app);

    if (values.isValid()) {
      const { rows = [] } = values.getData();
      const { value = "" } =
        rows.find((value) => value.slug === "file-scopes") || {};

      this.setData({
        "default.scopes": value ? value.split("\n") : ["default"],
      });
    }
  }

  getApi(options, type) {
    switch (type) {
      case "create": {
        //const { account, app, repository } = this.getData("params", {});
        const { account, app } = this.getData("params", {});
        return SystemApi.translation.create(
          //return ContentApi.translation.create(
          account,
          app,
          //repository,
          this.getData("default.fields")
        );
      }
      case "update": {
        //const { account, app, repository, id } = this.getData("params", {});
        const { account, app, id } = this.getData("params", {});
        const fields = this.getData("default.fields");
        const {
          entity,
          entity_slug,
          lang,
          slug,
          scope,
          html,
          translation,
          position,
          active,
        } = fields;
        return SystemApi.translation.update(account, app, id, {
          //return ContentApi.translation.update(account, repository, id, {
          entity,
          entity_slug,
          lang,
          slug,
          scope,
          html,
          translation,
          position,
          public: fields.public,
          active,
        });
      }
      case "retrieve": {
        //const { account, app, repository, id, entity } = this.getData(
        const { account, app, id, entity } = this.getData("params", {});
        //return ContentApi.translation.one(account, repository, id, {
        return SystemApi.translation.one(account, app, id, {
          params: { entity },
        });
      }
      default: {
        //
      }
    }
  }

  onValidate() {
    return {
      "fields.lang": [
        {
          rule: "required",
        },
      ],
      "fields.scope": [
        {
          rule: "required",
        },
      ],
      "fields.slug": [
        {
          rule: "required",
        },
      ],
      "fields.html": [
        {
          rule: "required",
        },
      ],
      "fields.translation": [
        {
          rule: "required",
        },
      ],
      "fields.position": [
        {
          rule: "required",
        },
        {
          rule: "integerPos",
          message: "invalid number",
        },
      ],
      "fields.public": [
        {
          rule: "required",
        },
      ],
    };
  }
}
