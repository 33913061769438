import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const UseStyles = makeStyles((theme) => ({
  switch: {
    marginBottom: theme.spacing(1),
  },
}));

const ExportDefault = (props) => {
  const { form } = props;
  const classes = UseStyles();

  let { path, dataForm = form, label, color, disabled, onChange } = props;

  color = color ? color : "primary";
  disabled = disabled ? true : false;

  const onChangeValue = (e) => {
    const checked = e.target.checked;

    dataForm.setData(
      { [`default.fields.${path}`]: checked ? 1 : 0 },
      {
        cb: () => {
          if (onChange) {
            onChange(checked, props);
          }
        },
      }
    );
  };

  let value = dataForm.getData(`default.fields.${path}`);

  return (
    <FormControlLabel
      className={classes.switch}
      control={
        <Switch
          checked={value === 1 || value === true}
          onChange={onChangeValue}
          color={color}
          disabled={disabled}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      }
      label={form.ucfirst(label)}
    />
  );
};

export default ExportDefault;
