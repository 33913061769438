import React from 'react';
import Button from '@material-ui/core/Button';
import AccountAppForm from '../../../../../Components/Form/Account/App';

const ExportDefault = (props) => {
    const { component } = props;
    
    return (
        <React.Fragment>
            <Button variant="contained" color="primary" onClick={() => {
                const { account, app } = component.getPage().getParams();
                const { slug, name } = component.getComponents().findById('account-app-form').first().getData('default.fields');
                const entity = 'account';
                const entity_slug = slug;
                const entity_name = name;

                component
                    .getComponents()
                    .findById('files-modal')
                    .forEach(c => c.setTitle(`${account} files`).setFileParams({account,app,entity,entity_slug,entity_name}).then(c => c.open()))
                ;
            }}>
                {component.ucfirst('files')}
            </Button>
            <AccountAppForm />
        </React.Fragment>
    );
}

export default ExportDefault;