import { Crud } from '../../../../Core/Components/Form/Crud';
import templateDefault from '../../../../Admin/template/form/type/normal';
import TextField from '../../../../Admin/template/form/fields/type/text';
import ActionField from '../../../../Admin/template/form/fields/type/action';
import AuthApi from '../../../../Api/Auth';

export default class LoginForm extends Crud {
    template = templateDefault;

    id = 'login-form';

    fields = [{
        'id': 'username',
        'path': 'username',
        'label': 'username',
        'required': true,
        'fullWidth': true,
        'render': TextField,
    },{
        'id': 'password',
        'path': 'password',
        'label': 'password',
        'type': 'password',
        'required': true,
        'fullWidth': true,
        'render': TextField
    },{
        'id': 'login',
        'label': 'login',
        'holder': 'action',
        'fullWidth': true,
        'render': ActionField
    }];

    getApi(options, type) {
        return AuthApi.login(this.getData('default.fields',{}));
    }

    afterCreate(r) {
        super.afterCreate(r);

        if (r.isValid()) {
            this.getHelpers('auth').set(r.getData());
            this.deleteData('default.fields');
            const redirect = this.getPage().getQuery('redirect','/');
            this.getPage().redirect(redirect,true);
        }
    }

    onValidate() {
        return {
            'fields.username': [{
                    'rule': 'required',
                }],
            'fields.password': [{
                    'rule': 'required',
                }],
        };
    }
}