import { Crud } from '../../../../../../Core/Components/Form/Crud';
import templateDefault from '../../../../../../Admin/template/form/type/normal';
import TextField from '../../../../../../Admin/template/form/fields/type/text';
import SwitchField from '../../../../../../Admin/template/form/fields/type/switch';
import ActionField from '../../../../../../Admin/template/form/fields/type/action';
import AccountApi from '../../../../../../Api/Account';

export default class AccountAppLanguageForm extends Crud {
    template = templateDefault;

    id = 'account-app-language-form';
    modal = 'account-app-language-modal';
    collection = 'account-app-languages-table';

    fields = [{
        'id': 'slug',
        'path': 'slug',
        'label': 'slug',
        'fullWidth': true,
        'render': TextField,
    },{
        'id': 'name',
        'path': 'name',
        'label': 'name',
        'fullWidth': true,
        'render': TextField,
    },{
        'id': 'public',
        'path': 'public',
        'label': 'public',
        'render': SwitchField
    },{
        'id': 'action',
        'holder': 'action',
        'label': (props) => {
            const { form } = props;
            return form.isOnUpdate() ? 'update': 'create';
        },
        'fullWidth': true,
        'render': ActionField
    }];

    onLoad(data) {
        super.onLoad(data);

        if (this.isOnUpdate()) {
            this.retrieve();
        }
    }

    getApi(options, type) {
        switch(type) {
            case 'create': {
                const { account, app } = this.getData('params',{});
                return AccountApi.language.create(account,app,this.getData('default.fields'));
            }
            case 'update': {
                const { account, app, id } = this.getData('params',{});
                const fields = this.getData('default.fields');
                const { slug, name } = fields;
                return AccountApi.language.update(account,app,id,{slug,name,public:fields.public});
            }
            case 'retrieve': {
                const { account, app, id } = this.getData('params',{});
                return AccountApi.language.one(account,app,id);
            }
            default: {
                //
            }
        }
    }

    onValidate() {
        return {
            'fields.slug': [{
                    'rule': 'required',
                }],
            'fields.name': [{
                    'rule': 'required',
                }],
            'fields.public': [{
                    'rule': 'required',
                }],
        };
    }
}