import { Crud } from '../../../../Core/Components/Form/Crud';
//import templateDefault from '../../templates/default/form/register';
import templateDefault from '../../../../Admin/template/form/type/normal';
import TextField from '../../../../Admin/template/form/fields/type/text';
import ActionField from '../../../../Admin/template/form/fields/type/action';
import AuthApi from '../../../../Api/Auth';

export default class RegisterForm extends Crud {
    template = templateDefault;

    id = 'register-form';

    fields = [{
        'id': 'name',
        'path': 'name',
        'label': 'name',
        'required': true,
        'fullWidth': true,
        'render': TextField,
    },{
        'id': 'username',
        'path': 'username',
        'label': 'username',
        'required': true,
        'fullWidth': true,
        'render': TextField,
    },{
        'id': 'password',
        'path': 'password',
        'label': 'password',
        'type': 'password',
        'required': true,
        'fullWidth': true,
        'render': TextField
    },{
        'id': 'verify',
        'path': 'verify',
        'label': 'verify',
        'type': 'password',
        'required': true,
        'fullWidth': true,
        'render': TextField
    },{
        'id': 'register',
        'label': 'register',
        'holder': 'action',
        'fullWidth': true,
        'render': ActionField
    }];

    getApi(options, type) {
        return AuthApi.register(this.getData('default.fields',{}));
    }

    afterCreate(r) {
        super.afterCreate(r);

        if (r.isValid()) {
            this.getHelpers('auth').set(r.getData());
            this.deleteData('default.fields');
            const redirect = this.getPage().getQuery('redirect','/');
            this.getPage().redirect(redirect,true);
        }
    }

    onValidate() {
        return {
            'fields.name': [{
                'rule': 'required',
            },{
                'rule': 'range',
                'min': 2,
                'max': 50,
                'message': 'name has to be from 2 up to 50 characters'
            }],
            'fields.username': [{
                    'rule': 'required',
                },{
                    'rule': 'email',
                    'message': 'invalid email'
                }],
            'fields.password': [{
                    'rule': 'required',
                },{
                    'rule': 'range',
                    'min': 6,
                    'max': 50,
                    'message': 'password has to be from 6 up to 50 characters'
                }],
            'fields.verify': [{
                    'rule': 'required',
                },{
                    'rule': 'range',
                    'min': 6,
                    'max': 50,
                    'message': 'password has to be from 6 up to 50 characters'
                }],
        };
    }
}