import React from "react";
import ReactDOM from "react-dom";
import App from "./modules/Apps/Account/Components/App";
import Env from "./modules/Core/Helpers/Env";
//import * as ServiceWorker from "./serviceWorkerRegistration";

const env = Env.get("env");
const serviceWorkerEnabled = ["production"];

ReactDOM.render(<App />, document.getElementById("root"));

if (serviceWorkerEnabled.indexOf(env) !== -1) {
  //ServiceWorker.register();
}
