import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ExportDefault = (props) => {
  const { component } = props;
  const message = component.getMessage();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={component.isOpened()}
      autoHideDuration={5000}
      onClose={() => component.close()}
    >
      <Alert
        onClose={() => component.close()}
        severity={component.getSeverity()}
      >
        {message ? component.ucfirst(message) : message}
      </Alert>
    </Snackbar>
  );
};

export default ExportDefault;
