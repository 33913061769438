import Env from "./Env";
import cookies from "react-cookies";

class Cookie {
  set(name, value, options) {
    cookies.save(name, value, options);
    return this;
  }

  get(name, defaultValue) {
    defaultValue = defaultValue || null;
    return cookies.load(name) || defaultValue;
  }

  setObject(name, value, options) {
    this.set(name, value, options);
    return this;
  }

  getObject(name, defaultValue) {
    let value = this.get(name, defaultValue);
    return value ? value : defaultValue;
  }

  remove(name, options) {
    cookies.remove(name, options);
    return this;
  }

  toggle(name, value) {
    if (this.get(name)) {
      this.remove(name);
    } else {
      this.set(name, value);
    }
  }
}

class LocalStorage {
  set(name, value, options) {
    localStorage.setItem(name, value);
    return this;
  }

  get(name, defaultValue) {
    defaultValue = defaultValue || null;
    return localStorage.getItem(name) || defaultValue;
  }

  setObject(name, value) {
    value = JSON.stringify(value);
    this.set(name, value);
    return this;
  }

  getObject(name, defaultValue) {
    let value = this.get(name, defaultValue);
    return value ? JSON.parse(value) : defaultValue;
  }

  remove(name, options) {
    localStorage.removeItem(name);
    return this;
  }

  toggle(name, value) {
    if (this.get(name)) {
      this.remove(name);
    } else {
      this.set(name, value);
    }
  }
}

const state = Env.isApp() ? new LocalStorage() : new Cookie();

export { Cookie, LocalStorage };
export default state;
