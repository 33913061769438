import React from "react";
import { DropzoneDialog } from "material-ui-dropzone";

const ExportDefault = (props) => {
  const { component } = props;

  return (
    <DropzoneDialog
      //acceptedFiles={component.getAcceptedFiles()}
      maxFileSize={component.getMaxFilesSize()}
      filesLimit={component.getFilesLimit()}
      cancelButtonText={component.ucfirst("close")}
      submitButtonText={component.ucfirst("upload")}
      dialogTitle={component.ucfirst("upload file")}
      open={component.isOpened()}
      maxWidth={component.getSize()}
      dropzoneText={component.ucfirst("drop files here")}
      onClose={() => component.onClose()}
      getFileAddedMessage={() => component.ucfirst("uploader files added")}
      getFileLimitExceedMessage={(amount) =>
        `${component.ucfirst("uploader files limit")}: ${amount}`
      }
      getDropRejectMessage={() => component.ucfirst("uploader files rejected")}
      onSave={(files) => component.uploadFiles(files)}
      showPreviews={true}
      showFileNamesInPreview={true}
    />
  );
};

export default ExportDefault;
