import Login from './Page/Login';
import Register from './Page/Register';
import Accounts from './Page/Accounts';
import Account from './Page/Account';
import AccountUser from './Page/Account/User';
import AccountApp from './Page/Account/App';

const Module = {
    Login,
    Register,
    Accounts,
    Account,
    AccountUser,
    AccountApp
};

export default Module;