import { Modal } from "../../Modal";
import templateContent from "../../../../template/collection/table/filters/content";
import templateActions from "../../../../template/collection/table/filters/actions";

class Filters extends Modal {
  templateContent = templateContent;
  templateActions = templateActions;

  getCollection() {
    return this.props.collection;
  }

  filter() {
    const filters = this.getData("default.filters", {});
    const condition = this.getData("default.condition", "and");

    this.props.collection
      .setData({
        "default.currentPage": 0,
        "default.filters": filters,
        "filters.condition": condition,
      })
      .retrieve();
  }

  clear() {
    this.deleteData("default.filters").deleteData("autocomplete.value");
    this.props.collection.clearFilters().retrieve();
  }

  isViewable(id) {
    return this.getPage().getQuery(`filter-${id}`);
  }
}

export { Filters };
export default new Filters();
