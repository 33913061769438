import { Component } from "../../../../Core/Component";
import templateDefault from "../../../template/widget/language/select";
import ShareApi from "../../../../Api/Share";

export default class LanguagesSelect extends Component {
  template = templateDefault;
  id = "page-language-select";

  onLoad() {
    super.onLoad();
    this.retrieveLanguages();
  }

  retrieveLanguages() {
    const envHelper = this.getHelpers("env");
    const app = envHelper.get("name");
    const defaultAccount = this.getPage().getParam(
      "account",
      envHelper.get("default_account")
    );

    const defaultRepository = envHelper.get("default_repository", app);

    const account = this.props.account || defaultAccount;
    const repository = this.props.repository || defaultRepository;

    ShareApi.content.languages(account, repository).then((r) => {
      if (r.isValid()) {
        this.setData({ "default.languages": r.getData() });
      }
    });
  }

  getLanguages() {
    let languages = this.getData("default.languages", []);

    if (languages.length === 0) {
      languages = [];
    }

    return languages;
  }

  getSelectedLanguage() {
    return this.getLanguages().length
      ? this.getData(
          "default.selectedLanguage",
          this.getHelpers("language").getLang()
        )
      : "";
  }

  selectLanguage(e) {
    const account = this.getPage().getParam("account");

    const language = e.target.value;
    this.getData("default.selectedLanguage", language);
    this.getHelpers("literal")
      .changeLanguage(language, { account })
      .then(() => this.getPage().refresh());
    this.getComponents()
      .findByGroup("translation")
      .forEach((c) => c.retrieve());
  }
}
