import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Copyright from './copyright';
import LanguageSelect from '../../../../../Admin/Components/Widget/Language/Select';

const UseStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'white'
  },
  grow: {
    flexGrow: 1,
  },
}));

const ExportDefault = (props) =>  {
  const classes = UseStyles();

  return (
    <React.Fragment>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <Copyright {...props} />
          <div className={classes.grow} />
          <LanguageSelect />
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default ExportDefault;
