import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const UseStyles = makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(1,0)
    },
}));

const ExportDefault = (props) => {
    const classes = UseStyles();

    return (
        <Divider className={classes.divider} />
    );
};

export default ExportDefault;