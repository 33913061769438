import { Component } from "../../../Core/Component";
import templateDefault from "../../template/widget/main_message";

class MainMessage extends Component {
  id = "main-message";
  template = templateDefault;

  open() {
    this.setData({ "default.opened": true });
  }

  close() {
    this.setData({ "default.opened": false });
  }

  isOpened() {
    return this.getData("default.opened", false);
  }

  setMessage(message) {
    return this.setData({ "default.message": message });
  }

  getMessage() {
    return this.getData("default.message", "message");
  }

  setSeverity(severity) {
    return this.setData({ "default.severity": severity });
  }

  getSeverity() {
    return this.getData("default.severity", "success");
  }
}

export { MainMessage };
export default new MainMessage();
