import Api from "../Core/Helpers/Api";

const api = Api.create("content");

const ExportDefault = {
  access: {
    all: function (account, entity, id, options) {
      return api.get(
        `account/${account}/access/entity/${entity}/${id}`,
        options
      );
    },
    create: function (account, entity, id, options) {
      return api.post(
        `account/${account}/access/entity/${entity}/${id}`,
        options
      );
    },
    update: function (account, entity, id, userId, options) {
      return api.put(
        `account/${account}/access/entity/${entity}/${id}/user/${userId}`,
        options
      );
    },
    delete: function (account, entity, id, userId, options) {
      return api.delete(
        `account/${account}/access/entity/${entity}/${id}/user/${userId}`,
        options
      );
    },
    valid: function (account, entity, id, options) {
      return api.get(
        `account/${account}/access/entity/${entity}/${id}`,
        options
      );
    },
  },
  repository: {
    collection: function (account, options) {
      return api.get(`account/${account}/repository/collection`, options);
    },
    create: function (account, options) {
      return api.post(`account/${account}/repository`, options);
    },
    one: function (account, id, options) {
      return api.get(`account/${account}/repository/${id}`, options);
    },
    update: function (account, id, options) {
      return api.put(`account/${account}/repository/${id}`, options);
    },
    delete: function (account, id, options) {
      return api.delete(`account/${account}/repository/${id}`, options);
    },
  },
  value: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/value/collection`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/value`,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/value/${id}`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/value/${id}`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/value/${id}`,
        options
      );
    },
  },
  hook: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/hook/collection`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/hook`,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/hook/${id}`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/hook/${id}`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/hook/${id}`,
        options
      );
    },
    execPost: function (account, repository, hook, version, options) {
      version = version || 1;

      return api.post(
        `account/${account}/repository/${repository}/hook/${hook}/${version}`,
        options
      );
    },
  },
  cron: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/cron/collection`,
        options
      );
    },
    deploy: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/cron/deploy`,
        options
      );
    },
    redeploy: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/cron/redeploy`,
        options
      );
    },
    undeploy: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/cron/undeploy`,
        options
      );
    },
    run: function (account, repository, id, options) {
      return api.post(
        `account/${account}/repository/${repository}/cron/${id}/run`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/cron`,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/cron/${id}`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/cron/${id}`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/cron/${id}`,
        options
      );
    },
  },
  language: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/language/collection`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/language`,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/language/${id}`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/language/${id}`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/language/${id}`,
        options
      );
    },
  },
  literal: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/literal/collection`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/literal`,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/literal/${id}`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/literal/${id}`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/literal/${id}`,
        options
      );
    },
  },
  translation: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/translation/collection`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/translation`,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/translation/${id}`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/translation/${id}`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/translation/${id}`,
        options
      );
    },
  },
  log: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/log/collection`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/log/${id}`,
        options
      );
    },
  },
  file: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/file/collection`,
        options
      );
    },
    create: function (account, repository, file, options) {
      return api.post(
        `account/${account}/repository/${repository}/file`,
        file,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/file/${id}`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/file/${id}`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/file/${id}`,
        options
      );
    },
    display: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/file/${id}/display`,
        options
      );
    },
    entity: {
      display: function (account, repository, slug, options) {
        return api.get(
          `account/${account}/repository/${repository}/file/entity/${slug}/display`,
          options
        );
      },
    },
  },
  property: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/property/collection`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/property`,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/property/${id}`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/property/${id}`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/property/${id}`,
        options
      );
    },
    seed: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/property/${id}/seed`,
        options
      );
    },
  },
  set: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/set/collection`,
        options
      );
    },
    select: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/set/select`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/set`,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/set/${id}`,
        options
      );
    },
    //
    info: function (account, repository, slug, options) {
      return api.get(
        `account/${account}/repository/${repository}/set/${slug}/info`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/set/${id}`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/set/${id}`,
        options
      );
    },
    build: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/set/${id}/build`,
        options
      );
    },
    property: {
      collection: function (account, repository, id, options) {
        return api.get(
          `account/${account}/repository/${repository}/set/${id}/property/collection`,
          options
        );
      },
      create: function (account, repository, id, options) {
        return api.post(
          `account/${account}/repository/${repository}/set/${id}/property`,
          options
        );
      },
      one: function (account, repository, id, propertyId, options) {
        return api.get(
          `account/${account}/repository/${repository}/set/${id}/property/${propertyId}`,
          options
        );
      },
      update: function (account, repository, id, propertyId, options) {
        return api.put(
          `account/${account}/repository/${repository}/set/${id}/property/${propertyId}`,
          options
        );
      },
      delete: function (account, repository, id, propertyId, options) {
        return api.delete(
          `account/${account}/repository/${repository}/set/${id}/property/${propertyId}`,
          options
        );
      },
      seed: function (account, repository, id, propertyId, options) {
        return api.put(
          `account/${account}/repository/${repository}/set/${id}/property/${propertyId}/seed`,
          options
        );
      },
    },
    group: {
      collection: function (account, repository, id, options) {
        return api.get(
          `account/${account}/repository/${repository}/set/${id}/group/collection`,
          options
        );
      },
      create: function (account, repository, id, options) {
        return api.post(
          `account/${account}/repository/${repository}/set/${id}/group`,
          options
        );
      },
      one: function (account, repository, id, groupId, options) {
        return api.get(
          `account/${account}/repository/${repository}/set/${id}/group/${groupId}`,
          options
        );
      },
      update: function (account, repository, id, groupId, options) {
        return api.put(
          `account/${account}/repository/${repository}/set/${id}/group/${groupId}`,
          options
        );
      },
      delete: function (account, repository, id, groupId, options) {
        return api.delete(
          `account/${account}/repository/${repository}/set/${id}/group/${groupId}`,
          options
        );
      },
      property: {
        collection: function (account, repository, id, groupId, options) {
          return api.get(
            `account/${account}/repository/${repository}/set/${id}/group/${groupId}/property/collection`,
            options
          );
        },
        create: function (account, repository, id, groupId, options) {
          return api.post(
            `account/${account}/repository/${repository}/set/${id}/group/${groupId}/property`,
            options
          );
        },
        one: function (account, repository, id, groupId, $propertyId, options) {
          return api.get(
            `account/${account}/repository/${repository}/set/${id}/group/${groupId}/property/${$propertyId}`,
            options
          );
        },
        update: function (
          account,
          repository,
          id,
          groupId,
          $propertyId,
          options
        ) {
          return api.put(
            `account/${account}/repository/${repository}/set/${id}/group/${groupId}/property/${$propertyId}`,
            options
          );
        },
        delete: function (
          account,
          repository,
          id,
          groupId,
          $propertyId,
          options
        ) {
          return api.delete(
            `account/${account}/repository/${repository}/set/${id}/group/${groupId}/property/${$propertyId}`,
            options
          );
        },
      },
    },
    setting: {
      collection: function (account, repository, id, options) {
        return api.get(
          `account/${account}/repository/${repository}/set/${id}/setting/collection`,
          options
        );
      },
      create: function (account, repository, id, options) {
        return api.post(
          `account/${account}/repository/${repository}/set/${id}/setting`,
          options
        );
      },
      one: function (account, repository, id, settingId, options) {
        return api.get(
          `account/${account}/repository/${repository}/set/${id}/setting/${settingId}`,
          options
        );
      },
      update: function (account, repository, id, settingId, options) {
        return api.put(
          `account/${account}/repository/${repository}/set/${id}/setting/${settingId}`,
          options
        );
      },
      delete: function (account, repository, id, settingId, options) {
        return api.delete(
          `account/${account}/repository/${repository}/set/${id}/setting/${settingId}`,
          options
        );
      },
    },
  },
  item: {
    collection: function (account, repository, setId, options) {
      return api.get(
        `account/${account}/repository/${repository}/item/set/${setId}/collection`,
        options
      );
    },
    select: function (account, repository, setId, options) {
      return api.get(
        `account/${account}/repository/${repository}/item/set/${setId}/select`,
        options
      );
    },
    one: function (account, repository, setId, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/item/set/${setId}/item/${id}`,
        options
      );
    },
    visible: function (account, repository, setId, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/item/set/${setId}/item/${id}/visible`,
        options
      );
    },
    create: function (account, repository, setId, options) {
      return api.post(
        `account/${account}/repository/${repository}/item/set/${setId}`,
        options
      );
    },
    update: function (account, repository, setId, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/item/set/${setId}/item/${id}`,
        options
      );
    },
    delete: function (account, repository, setId, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/item/set/${setId}/item/${id}`,
        options
      );
    },
    instance: function (account, repository, setId, id, options) {
      return api.post(
        `account/${account}/repository/${repository}/item/set/${setId}/item/${id}/instance`,
        options
      );
    },
    action: function (
      account,
      repository,
      setId,
      id,
      action,
      type,
      scope,
      options
    ) {
      return api.post(
        `account/${account}/repository/${repository}/item/set/${setId}/item/${id}/action/${action}/type/${type}/scope/${scope}`,
        options
      );
    },
  },
  user: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/user/collection`,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/user/${id}`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/user`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/user/${id}`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/user/${id}`,
        options
      );
    },
    asset: {
      address: {
        collection: function (account, repository, id, options) {
          return api.get(
            `account/${account}/repository/${repository}/user/${id}/asset/address/collection`,
            options
          );
        },
        one: function (account, repository, id, addressId, options) {
          return api.get(
            `account/${account}/repository/${repository}/user/${id}/asset/address/${addressId}`,
            options
          );
        },
        create: function (account, repository, id, options) {
          return api.post(
            `account/${account}/repository/${repository}/user/${id}/asset/address`,
            options
          );
        },
        update: function (account, repository, id, addressId, options) {
          return api.put(
            `account/${account}/repository/${repository}/user/${id}/asset/address/${addressId}`,
            options
          );
        },
        delete: function (account, repository, id, addressId, options) {
          return api.delete(
            `account/${account}/repository/${repository}/user/${id}/asset/address/${addressId}`,
            options
          );
        },
      },
      business: {
        collection: function (account, repository, id, options) {
          return api.get(
            `account/${account}/repository/${repository}/user/${id}/asset/business/collection`,
            options
          );
        },
        one: function (account, repository, id, businessId, options) {
          return api.get(
            `account/${account}/repository/${repository}/user/${id}/asset/business/${businessId}`,
            options
          );
        },
        create: function (account, repository, id, options) {
          return api.post(
            `account/${account}/repository/${repository}/user/${id}/asset/business`,
            options
          );
        },
        update: function (account, repository, id, businessId, options) {
          return api.put(
            `account/${account}/repository/${repository}/user/${id}/asset/business/${businessId}`,
            options
          );
        },
        delete: function (account, repository, id, businessId, options) {
          return api.delete(
            `account/${account}/repository/${repository}/user/${id}/asset/business/${businessId}`,
            options
          );
        },
      },
    },
  },
  action: {
    internal: function (account, repository, action, type, scope, options) {
      return api.post(
        `account/${account}/repository/${repository}/action/internal/${action}/type/${type}/scope/${scope}`,
        options
      );
    },
  },
  build: function (account, repository, options) {
    return api.post(
      `account/${account}/repository/${repository}/build`,
      options
    );
  },
  cache: {
    purge: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/cache/purge`,
        options
      );
    },
  },
};

export default ExportDefault;
