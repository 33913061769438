import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const actionStyles = makeStyles((theme) => ({
  actionsRoot: {
    //justifyContent: "center",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ExportDefault = (props) => {
  const { component } = props;
  const id = component.getId();
  const TemplateContent = component.templateContent;
  const TemplateActions = component.templateActions;
  const classes = actionStyles();

  return (
    <Dialog
      onClose={() => component.onClose()}
      onEnter={() => component.onBeforeOpen()}
      onEntered={() => component.onOpen()}
      aria-labelledby={`${id}-dialog-title`}
      fullWidth={true}
      maxWidth={component.getSize()}
      open={component.isOpened()}
      fullScreen={component.fullScreen}
    >
      <DialogTitle
        id={`${id}-dialog-title`}
        onClose={() => component.onClose()}
      >
        {component.ucfirst(component.getTitle())}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {TemplateContent && <TemplateContent {...props} />}
      </DialogContent>
      {TemplateActions && <Divider />}
      <DialogActions classes={{ root: classes.actionsRoot }}>
        {TemplateActions && <TemplateActions {...props} />}
      </DialogActions>
    </Dialog>
  );
};

export default ExportDefault;
