const ExportDefault = (params) => {
    params = params || {};

    return {
        panel: {
            header: {
                marginBottom: '10px'
            },
            main: {
                
            },
            footer: {

            }
        }
    }
}

export default ExportDefault;