import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextRenderer from "../collection/render/text";
import { Filters } from "../../Components/Widget/Collection/Table/Filters";

const UseStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  paper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tableContainer: {
    height: "100%",
  },
  table: {
    minWidth: 750,
  },
  tableBordered: {
    border: "1px solid black",
  },
  tableCellRoot: {
    //padding: "7px 10px",
  },
  tableCell: {
    background: "white",
    //whiteSpace: "nowrap",
  },
  tableCellBordered: {
    border: "1px solid black",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  sticky: {
    position: "sticky",
    right: 0,
    zIndex: 1,
    background: "white",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    flexShrink: 1,
  },
  title: {
    flex: "1 1 100%",
  },
  withFilters: {
    fontSize: "1em",
    fontWeight: "bold",
    background: "#f44336",
    color: "white",
  },
  filterValues: {
    color: "#fc034e",
    fontSize: "14px",
    fontWeight: "bold",
  },
}));

const useTablePaginationStyles = makeStyles((theme) => ({
  root: {
    overflow: "initial",
  },
}));

const FiltersRender = (props) => {
  //const classes = useToolbarStyles();
  const { component } = props;
  const filters = component.getData("default.filters", {});
  const filtersLength = Object.keys(filters).length;
  //const render = [];

  return filtersLength ? filtersLength : "";

  /*
  let index = 0;
  for (let name in filters) {
    const value = filters[name];

    if (value) {
      render.push(
        <span
          className={classes.filterValues}
          key={`${component.getId()}-filter-${name}`}
        >
          {value}
          {index++ < filtersLength - 1 ? ", " : ""} &nbsp;
        </span>
      );
    }
  }

  return render;
  */
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { component } = props;
  // const filters = component.getData("default.filters", {});
  // const hasFilters = Object.keys(filters).length > 0;
  // const hasFilters = Object.keys(filters).length > 0;
  const hasFilters = component.getFiltersAmount() > 0;

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" component="div">
        {component.ucfirst(component.getTitle())}
      </Typography>

      {component.withFilters && (
        <Tooltip
          title="Filter"
          className={`${hasFilters ? classes.withFilters : ""}`}
          onClick={() => {
            const id = component.getId();
            const title = component.getTitle();

            component
              .getComponents()
              .findById(`${id}-filters`)
              .forEach((c) =>
                c.setTitle(`${title} ${component.ucfirst("filters")}`).open()
              );
          }}
        >
          <IconButton aria-label="filter">
            <FiltersRender {...props} />
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const ExportDefault = (props) => {
  const { component } = props;
  const classes = UseStyles();
  const tablePaginationClasses = useTablePaginationStyles();
  const isBordered = component.isBordered() === "1";
  const noWrap = component.props.noWrap ? "nowrap" : "";

  return (
    <div id={`${component.getId()}`} className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar component={component} />
        {component.withFilters && (
          <Filters id={`${component.getId()}-filters`} collection={component} />
        )}
        <TableContainer className={classes.tableContainer}>
          <Table
            className={`${classes.table} ${
              isBordered ? classes.tableBordered : ""
            }`}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {component.getColumns().map((column) => {
                  const { id } = column;
                  const key = `${component.getId()}-head-label-${id}`;
                  const orderType = component.getOrderType();
                  const isOrderedBy = component.isOrderedBy(column);
                  const label = component.getColumnLabel(column);
                  const align = component.getColumnAlign(column);
                  const display = component.canDisplay(column);

                  return (
                    display && (
                      <TableCell
                        classes={
                          {
                            //root: classes.tableCellRoot,
                          }
                        }
                        key={key}
                        align={align}
                        sortDirection={isOrderedBy ? orderType : false}
                        style={{ whiteSpace: noWrap }}
                        className={`${classes.tableCell} ${
                          isBordered ? classes.tableCellBordered : ""
                        } ${column.sticky ? classes.sticky : ""}`}
                      >
                        {component.isColumnSortable(column) ? (
                          <TableSortLabel
                            active={isOrderedBy}
                            direction={orderType}
                            onClick={() =>
                              component.changeOrderBy(column).retrieve()
                            }
                          >
                            {component.ucfirst(label)}
                            {isOrderedBy ? (
                              <span className={classes.visuallyHidden}>
                                {orderType === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          component.ucfirst(label)
                        )}
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {component.getRows().map((row, index) => {
                const rowId = `${component.getId()}-body-row-${component.getRowKey(
                  row,
                  index
                )}`;
                return (
                  <TableRow key={rowId} id={rowId}>
                    {component.getColumns().map((column) => {
                      const Render = column.render || TextRenderer;
                      const props = { component, row, column };
                      const hidden = column.hidden
                        ? column.hidden(props)
                        : false;
                      const display = component.canDisplay(column);

                      return (
                        display && (
                          <TableCell
                            classes={
                              {
                                //root: classes.tableCellRoot,
                              }
                            }
                            key={`${rowId}-${column.id}`}
                            align={component.getColumnAlign(column)}
                            style={{ whiteSpace: noWrap }}
                            className={`${classes.tableCell} ${
                              isBordered ? classes.tableCellBordered : ""
                            } ${column.sticky ? classes.sticky : ""}`}
                          >
                            {!hidden && (
                              <div>
                                <Render {...props} />
                              </div>
                            )}
                          </TableCell>
                        )
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {component.withPagination && (
          <TablePagination
            classes={{
              root: tablePaginationClasses.root,
            }}
            rowsPerPageOptions={component.getPageAmounts()}
            component="div"
            count={component.getTotal()}
            rowsPerPage={component.getPageAmount()}
            page={component.getCurrentPage()}
            onChangePage={(event, newPage) =>
              component.changePage(newPage).retrieve()
            }
            onChangeRowsPerPage={(e) =>
              component.setPageAmount(e.target.value).retrieve()
            }
            labelRowsPerPage={component.ucfirst("rows")}
          />
        )}
      </Paper>
    </div>
  );
};

export default ExportDefault;
