const ExportDefault = (props) => {
  const { component, column, row } = props;
  const { buildContent, ellipsis } = column;
  let content = buildContent
    ? buildContent(props)
    : component.getColumnRowValue(column, row);

  if (ellipsis && content && typeof content === "string") {
    const cutted = content.substring(0, ellipsis || 20);
    content = `${cutted}${cutted.length !== content.length ? "..." : ""}`;
  }

  return content;
};

export default ExportDefault;
