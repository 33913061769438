import React from 'react';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Button } from '@material-ui/core';

const UseStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
    card: {
      marginBottom: 10,
    },
    message: {
        margin: theme.spacing(0,0,1,0),
    }
  })
);

const ExportDefault = (props) => {
    const { component } = props;
    const classes = UseStyles();
    const entities = component.getData('default.entities',[]);
    const message = component.getData('default.message');
   
    return (
        <React.Fragment >
            {
                message && <Alert severity="success" className={classes.message}>{component.ucfirst(message)}</Alert>
            }
            <div className={classes.root}>
                <Grid container spacing={2}>
                {
                    entities.map(entity => {
                        const { slug, actions } = entity;

                        return (
                            <Grid key={`actions-entity-${slug}`} item xs={6}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {component.ucfirst(slug)}
                                        </Typography>
                                        <Box component="div">
                                            <Grid container spacing={3}>
                                                {actions.map(action => {
                                                    return (
                                                        <Grid key={`actions-entity-${slug}-action-${action.slug}`} item xs={6}>
                                                            <FormControlLabel
                                                                control={<Switch 
                                                                    checked={component.isActionChecked(slug,action.slug)} 
                                                                    onChange={e => component.toggleAction(slug,action.slug)} 
                                                                    name="checkedA" 
                                                                    color="primary"
                                                                />}
                                                                label={component.ucfirst(action.name)}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })
                }
                </Grid>
            </div>
            <Button variant="contained" color="primary" onClick={e => component.submitActions()} fullWidth={true}>
                {component.ucfirst('save')}
            </Button>
        </React.Fragment>
    );
}

export default ExportDefault;