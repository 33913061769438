import { Component } from "../../../Core/Component";
import templateDefault from "../../template/widget/tabs";

class Tabs extends Component {
  template = templateDefault;
  tabs = [];

  getTabs() {
    return this.tabs || [];
  }
}

export { Tabs };
export default new Tabs();
