import { Modal } from '../../../../../../Admin/Components/Widget/Modal';
import templateContent from '../../../Form/Account/User/NewApp';

export default class NewAppModal extends Modal {
    id = 'new-app-modal';
    title = 'new app';
    templateContent = templateContent;

    onBeforeOpen() {
        this.getComponents().findById('new-app-form').forEach(c => c.setData({'default.fields':{},'error':{}}));
    }
}