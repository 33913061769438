import { Crud } from "../../../../Core/Components/Form/Crud";
import templateDefault from "../../../../Admin/template/form/type/normal";
import TextField from "../../../../Admin/template/form/fields/type/text";
import SwitchField from "../../../../Admin/template/form/fields/type/switch";
import Divider from "../../../../Admin/template/form/fields/type/divider";
import ActionField from "../../../../Admin/template/form/fields/type/action";
import AccountApi from "../../../../Api/Account";

export default class AccountForm extends Crud {
  template = templateDefault;

  id = "account-form";

  fields = [
    {
      id: "slug",
      path: "slug",
      label: "account",
      disabled: true,
      fullWidth: true,
      variant: "filled",
      render: TextField,
    },
    {
      id: "plan_slug",
      path: "plan_slug",
      label: "plan_slug",
      disabled: true,
      fullWidth: true,
      variant: "filled",
      render: TextField,
    },
    {
      id: "active",
      path: "active",
      label: "active",
      render: SwitchField,
      canDisplay: (props) => props.form.getHelpers("auth").isSu(),
    },
    {
      render: Divider,
      canDisplay: (props) => props.form.getHelpers("auth").isSu(),
    },
    {
      id: "update",
      label: "update",
      holder: "action",
      render: ActionField,
      canDisplay: (props) => props.form.getHelpers("auth").isSu(),
    },
  ];

  onLoad() {
    super.onLoad();
    this.setData({ "default.state": "update" }).retrieve();
  }

  getApi(options, type) {
    switch (type) {
      case "update": {
        const { account } = this.getPage().getParams();
        const { active } = this.getData("default.fields", {});
        return AccountApi.account.update(account, { active });
      }
      case "retrieve": {
        const { account, id } = this.getPage().getParams();
        return AccountApi.account.one(account, id);
      }
      default: {
        //
      }
    }
  }

  afterRetrieve(r) {
    super.afterRetrieve(r);

    if (r.isValid()) {
      const { slug } = r.getData();

      this.getComponents()
        .findById("page-breadcrumbs")
        .forEach((breadcrumbs) => {
          breadcrumbs.setData({ "default.account.name": slug });
        });
    }
  }
}
