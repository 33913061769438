import { Tabs } from "../../../../../Admin/Components/Widget/Tabs";
import Details from "../../../templates/default/panel/account/app/details";
import Values from "../../../templates/default/panel/account/app/values";
import Languages from "../../../templates/default/panel/account/app/languages";
import Literals from "../../../templates/default/panel/account/app/literals";
import Log from "../../../templates/default/panel/account/app/log";
import JobFailed from "../../../templates/default/panel/account/app/job/failed";

export default class AccountAppTabs extends Tabs {
  id = "account-app-tabs";

  tabs = [
    {
      id: "details",
      label: "details",
      render: Details,
    },
    {
      id: "values",
      label: "values",
      render: Values,
    },
    {
      id: "languages",
      label: "languages",
      render: Languages,
    },
    {
      id: "literals",
      label: "literals",
      render: Literals,
    },
    {
      id: "log",
      label: "log",
      render: Log,
    },
    {
      id: "job-failed",
      label: "job failed",
      render: JobFailed,
    },
  ];
}
