import { Crud } from '../../../../../Core/Components/Form/Crud';
//import templateDefault from '../../../templates/default/form/account/app';
import templateDefault from '../../../../../Admin/template/form/type/normal';
import TextField from '../../../../../Admin/template/form/fields/type/text';
import AccountApi from '../../../../../Api/Account';

export default class AccountAppForm extends Crud {
    template = templateDefault;

    id = 'account-app-form';

    fields = [{
        'id': 'slug',
        'path': 'slug',
        'label': 'app',
        'disabled': true,
        'fullWidth': true,
        'variant': 'filled',
        'render': TextField,
    }];

    onLoad() {
        super.onLoad();
        this.retrieve();
    }

    getApi(options, type) {
        const { account, app } = this.getPage().getParams();
        return AccountApi.account.app.one(account,app);
    }

    afterRetrieve(r) {
        super.afterRetrieve(r);

        if (r.isValid()) {
            const { account } = this.getPage().getParams();
            const { slug } = r.getData();

            this.getComponents().findById('page-breadcrumbs').forEach(breadcrumbs => {
                breadcrumbs
                    .setData({
                        'default': {
                            account: {
                                slug: account
                            },
                            app: {
                                slug
                            }
                        }
                    })
                ;
            }); 
        }
    }
}