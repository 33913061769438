import { Modal } from "../Modal";
import templateContent from "../../../template/widget/files/modal";
import ShareApi from "../../../../Api/Share";

export default class FileModal extends Modal {
  id = "files-modal";
  size = "xl";
  title = "files";
  templateContent = templateContent;

  setFileParams({
    account,
    app,
    repository,
    entity,
    entity_slug,
    entity_name,
  }) {
    return new Promise((resolve, reject) => {
      const selectedValues = ["files-limit", "max-file-size", "accepted-files"];

      ShareApi.value.app(account, app).then((r) => {
        if (r.isValid()) {
          let values = {};

          r.getData().forEach((value) => {
            if (selectedValues.indexOf(value.slug) >= 0) {
              values[value.slug] = value;
            }
          });

          this.setData({
            "default.values": values,
            params: {
              account,
              app,
              repository,
              entity,
              entity_slug,
              entity_name,
            },
          });

          resolve(this);
        } else {
          reject(null, this);
        }
      });
    });
  }

  onOpen() {
    const components = this.getComponents();
    const { account, app, repository, entity, entity_slug } =
      this.getData("params");

    components
      .findById("files-table")
      .first()
      .setData({
        params: {
          account,
          app,
          repository,
          entity,
          entity_slug,
        },
      })
      .retrieve();
  }

  prepare() {
    const { account, app, repository, entity, entity_slug, entity_name } =
      this.getData("params");

    this.getComponents()
      .findById("files-uploader")
      .forEach((c) =>
        c.setData({
          params: {
            account,
            app,
            repository,
            entity,
            entity_slug,
            entity_name,
          },
        })
      );

    this.getComponentsState().save("files-form-form", {
      params: {
        account,
        app,
      },
    });

    return this;
  }
}
