import { createMuiTheme } from '@material-ui/core/styles';

const ExportDefault = (params) => {
  return createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#1976d2',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    }
  });
};

export default ExportDefault;
