import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AccountUsersTable from '../../../../Components/Collection/Account/Users';
import WidgetsStyles from '../../../../styles/widgets';
import NewUserModal from '../../../../Components/Modal/Account/NewUser';

const UseStyles = makeStyles(WidgetsStyles().panel);

const ExportDefault = (props) => {
    const { component } = props;
    const classes = UseStyles();
    const { account } = component.getPage().getParams();
    const canAddUser = component.getHelpers('auth').isOwner(account);

    return (
        <React.Fragment>
            <NewUserModal />
            <Box className={classes.header}>
                {
                    canAddUser && 
                    <Button variant="contained" color="primary" onClick={() => component.getComponents().findById('new-user-modal').forEach(c => c.open())}>
                        {component.ucfirst('add new user')}
                    </Button>
                }
            </Box>
            <Box className={classes.main}>
                <AccountUsersTable />
            </Box>
        </React.Fragment>
    );
}

export default ExportDefault;