import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";

const UseFiltersStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  action: {
    marginRight: theme.spacing(1),
  },
}));

const ExportDefault = (props) => {
  const { component } = props;
  const classes = UseFiltersStyles();

  return (
    <Box className={classes.root}>
      <FormControlLabel
        control={
          <Switch
            checked={component.getData("default.condition", "and") === "or"}
            onChange={() => {
              const condition = component.getData("default.condition", "and");

              component.setData({
                "default.condition": condition === "and" ? "or" : "and",
              });
            }}
            color="primary"
          />
        }
        label={component.ucfirst("or condition")}
      />

      <Button
        className={classes.action}
        variant="contained"
        color="secondary"
        onClick={() => component.clear()}
      >
        {component.ucfirst("clear")}
      </Button>
      <Button
        className={classes.action}
        variant="contained"
        color="primary"
        onClick={() => component.filter()}
      >
        {component.ucfirst("filter")}
      </Button>
    </Box>
  );
};

export default ExportDefault;
