import { Component } from "../../../Core/Component";
import templateDefault from "../../template/widget/modal";

class Modal extends Component {
  template = templateDefault;

  size = "xs";
  title = "";
  widget = true;
  fullScreen = false;

  setTitle(title) {
    return this.setData({ "default.title": title });
  }

  getTitle() {
    return this.getData("default.title", this.title);
  }

  getSize() {
    return this.getData("default.size", this.size);
  }

  setSize(size) {
    return this.setData({ "default.size": size });
  }

  open() {
    return this.setData({ "default.opened": true });
  }

  close() {
    return this.setData({ "default.opened": false });
  }

  isOpened() {
    return this.dataEqualsTo("default.opened", true);
  }

  onClose() {
    this.close();
  }

  onBeforeOpen() {
    //
  }

  onOpen() {
    //
  }
}

export { Modal };
export default new Modal();
