import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link'; 
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Image from '../../../../../Core/templates/widget/image';

const UseStyles = makeStyles((theme) => ({
    image: {
        textAlign: 'center',
    },
    name: {
        textAlign: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        fontSize: 16,
        textTransform: 'capitalize'
    },
}));

const ExportDefault = (props) => {
    const { component } = props;
    const classes = UseStyles();
    const accounts = component.getData('default.accounts',[]);
    const urlHelper = component.getHelpers('url');
    const app = component.getHelpers('env').get('name');
    const load = '/images/loading.png';
    const error = '/images/not-found.png';
        
    return (
        <Grid container spacing={2}>
            {accounts.map(account => {
                const src = urlHelper.createAccountImageUrl(account.slug,app,'account','logo');
                //const error = urlHelper.createAccountImageUrl(account.slug,app,'account','file-not-found');
                //const load = urlHelper.createAccountImageUrl(account.slug,app,'account','file-loading');

                return (
                    <Grid item lg={2} md={4} sm={6} xs={12} key={`app-account-${account.slug}`}>
                        <Paper>
                            <Link component={RouterLink} to={`/account/${account.slug}`} color="inherit">
                                <Box component="div" className={classes.image}>
                                    <Image 
                                        width={150}
                                        buildSrc={() => src} 
                                        buildError={() => error} 
                                        buildLoad={() => load} 
                                    />
                                </Box>
                                <Box component="div" className={classes.name}>
                                    {account.slug}
                                </Box>
                            </Link>
                        </Paper>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default ExportDefault;