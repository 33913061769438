import { Table } from "../Collection/Table";
import DateRangeFilterRenderer from "../../../template/collection/render/dateRangeFilter";
import ActionRenderer from "../../../template/collection/render/action";
import CheckedRenderer from "../../../template/collection/render/checked";
import SystemApi from "../../../../Api/System";
//import ContentApi from "../../../../Api/Content";

export default class TranslationTable extends Table {
  id = "translation-table";
  title = "translations";

  filters = [
    {
      id: "scope",
      label: "scope",
    },
    {
      id: "data",
      label: "data",
    },
    {
      id: "created_at",
      labelFrom: "created at (from)",
      labelTo: "created at (to)",
      fitlerRender: DateRangeFilterRenderer,
    },
  ];

  columns = [
    {
      id: "id",
      label: "id",
      align: "left",
      bind: "id",
      sortable: true,
    },
    {
      id: "lang",
      label: "lang",
      align: "left",
      bind: "lang",
      sortable: true,
    },
    {
      id: "slug",
      label: "slug",
      align: "left",
      bind: "slug",
      sortable: true,
    },
    {
      id: "scope",
      label: "scope",
      align: "left",
      bind: "scope",
      sortable: true,
    },
    {
      id: "translation",
      label: "translation",
      align: "left",
      bind: "translation",
    },
    {
      id: "position",
      label: "position",
      align: "left",
      bind: "position",
    },
    {
      id: "public",
      label: "public",
      align: "left",
      bind: "public",
      isChecked: (props) => props.row.public,
      render: CheckedRenderer,
    },
    {
      id: "html",
      label: "html",
      align: "left",
      bind: "html",
      isChecked: (props) => props.row.html,
      render: CheckedRenderer,
    },
    {
      id: "created_at",
      label: "created at",
      align: "right",
      bind: "created_at",
      sortable: true,
    },
    {
      id: "delete",
      align: "right",
      type: "secondary",
      text: "delete",
      hidden: (props) => {
        const { row } = props;
        return row.owner === 1;
      },
      click: (props) => {
        const { component, row } = props;

        if (window.confirm(component.ucfirst("are you sure?"))) {
          const params = this.getData("params");
          //const { account, app, repository, entity } = params;
          const { account, app, entity } = params;
          const { id } = row;
          //const { _id } = row;

          //ContentApi.translation.delete(account, repository, _id, { params: { entity } })
          SystemApi.translation
            .delete(account, app, id, { params: { entity } })
            .then((r) => {
              if (r.isValid()) {
                component.retrieve();
              }
            });
        }
      },
      render: ActionRenderer,
    },
    {
      id: "edit",
      align: "right",
      text: "edit",
      click: (props) => {
        const { component, row } = props;
        const { id } = row;
        //const { _id } = row;
        const { account, app, repository, entity } = component.getData(
          "params",
          {}
        );

        component.getComponentsState().save("translation-form-form", {
          default: {
            fields: {},
            state: "update",
          },
          params: {
            account,
            app,
            repository,
            entity,
            id,
            //id: _id,
          },
        });

        component
          .getComponents()
          .findById("translation-form-modal")
          .forEach((c) => c.setTitle("edit translation").open());
      },
      render: ActionRenderer,
    },
  ];

  getApi(options) {
    //const { account, app, repository, entity, entity_slug } = this.getData(
    const { account, app, entity, entity_slug } = this.getData("params", {});
    options.params.entity = entity;
    options.params.entity_slug = entity_slug;
    return SystemApi.translation.collection(account, app, options);
    //return ContentApi.translation.collection(account, repository, options);
  }

  afterRetrieve(r) {
    if (r.isValid()) {
      this.setCollectionData(r.getData());
    } else {
      this.getPage().redirect(`/`);
    }
  }
}
