import React from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import ErrorField from "../error";

const ExportDefault = (props) => {
  const { form } = props;

  let {
    path,
    label,
    fullWidth,
    required,
    type,
    multiline,
    variant,
    margin,
    disabled,
    rows,
    onChange,
  } = props;

  type = type ? type : "text";
  margin = margin ? margin : "normal";
  variant = variant ? variant : "outlined";
  fullWidth = fullWidth ? true : false;
  disabled = disabled ? true : false;
  multiline = multiline ? true : false;
  rows = rows ? rows : 4;

  const onChangeValue = (e) => {
    const value = e.target.value;

    form.setData(
      { [`default.fields.${path}`]: value },
      {
        cb: () => {
          if (onChange) {
            onChange(value, props);
          }
        },
      }
    );
  };

  let value = form.getData(`default.fields.${path}`, "");
  value = value === null ? "" : value;

  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      error={form.dataExists(`error.fields.${path}`)}
    >
      <TextField
        fullWidth={fullWidth}
        type={type}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        label={form.ucfirst(label) + `${required ? " *" : ""}`}
        value={value}
        onChange={onChangeValue}
        error={form.dataExists(`error.fields.${path}`)}
        variant={variant}
      />
      <ErrorField component={form} name={path} />
    </FormControl>
  );
};

export default ExportDefault;
