import { Component } from "../../../../../../Core/Component";
import defaultTemplate from "../../../../../template/collection/render/preview";
import ContentApi from "../../../../../../Api/Content";

class PreviewRender extends Component {
  template = defaultTemplate;

  onLoad(data) {
    super.onLoad(data);
    const { row } = this.extractData();

    if (row) {
      this.open(row);
    }
  }

  extractData() {
    const { extractData } = this.props.column;
    return extractData ? extractData(this.props) : this.props;
  }

  open(data) {
    const { account, repository } = this.getPage().getParams();
    const { entity, _id, slug } = data;

    if (_id) {
      ContentApi.file
        .display(account, repository, _id)
        .then((r) => this.setFile(r.getData()));
    } else if (slug) {
      ContentApi.file.entity
        .display(account, repository, slug, { params: { entity } })
        .then((r) => this.setFile(r.getData()));
    }
  }

  setFile(file) {
    this.setData({ "default.file": file });
    return this;
  }

  getFile() {
    return this.getData("default.file", {}) || {};
  }

  getSrcData() {
    const { type = null, data = null } = this.getFile();
    return type && data ? `data:${type};base64,${data}` : null;
  }

  isImage() {
    const { type = null } = this.getFile();
    return type && type.startsWith("image/");
  }
}

export { PreviewRender };
export default new PreviewRender();
