import React from 'react';
import CheckIcon from '@material-ui/icons/Check';

const ExportDefault = (props) => {
    const { column } = props;
    const { isChecked } = column;
    const checked = isChecked(props);

    return checked && <CheckIcon />;
};

export default ExportDefault;