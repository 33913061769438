import { Crud } from '../../../../../Core/Components/Form/Crud';
//import templateDefault from '../../../templates/default/form/account/newUser';
import templateDefault from '../../../../../Admin/template/form/type/normal';
import TextField from '../../../../../Admin/template/form/fields/type/text';
import ActionField from '../../../../../Admin/template/form/fields/type/action';
import AccountApi from '../../../../../Api/Account';

export default class NewUserForm extends Crud {
    template = templateDefault;

    id = 'new-user-form';
    modal = 'new-user-modal';
    collection = 'account-users-table';

    fields = [{
        'id': 'username',
        'path': 'username',
        'label': 'username',
        'fullWidth': true,
        'render': TextField,
    },{
        'id': 'create',
        'label': 'create',
        'holder': 'action',
        'fullWidth': true,
        'render': ActionField
    }];

    getApi(options, type) {
        const { account } = this.getPage().getParams();
        return AccountApi.account.user.create(account,this.getData('default.fields'));
    }

    onValidate() {
        return {
            'fields.username': [{
                    'rule': 'required',
                }],
        };
    }
}