import React from 'react';
import Typography from '@material-ui/core/Typography';

const ExportDefault = (props) => {
    const { component } = props;

    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {component.getHelpers('env').get('project')} {new Date().getFullYear()}
        </Typography>
    );
}

export default ExportDefault;