import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import HeaderMainFooterLayout from '../../../../../Admin/Components/Layout/HeaderMainFooter';
import theme from '../../../styles/themes/theme1';
import Container from '@material-ui/core/Container';
import RegisterForm from '../../../Components/Form/Register';
import PageLoader from '../../../Components/Widget/PageLoader';
import BottomBar from '../../../Components/Widget/BottomBar';

const pageTheme = theme();

const UseStyles = makeStyles({
  paper: {
    marginTop: pageTheme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: pageTheme.spacing(1),
    backgroundColor: pageTheme.palette.primary.main,
  }
});

const ExportDefault = (props) => {
  const { component } = props;
  const classes = UseStyles();
  const redirect = component.getQuery('redirect');

  return (
    <ThemeProvider theme={pageTheme}>
      <PageLoader />
      <HeaderMainFooterLayout page={true} paddingBottom={70} renderMain={() => 
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <RegisterForm />
            <Grid container>
            <Grid item xs></Grid>
              <Grid item>
                <Link component={RouterLink} to={`/login${redirect ? `?redirect=${redirect}`:''}`} variant="body2">
                  {component.ucfirst("you already have an account? Login")}
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      } />
      <BottomBar />
    </ThemeProvider>
  );
}

export default ExportDefault;