import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Uploader from '../../../Components/Widget/Files/Uploader';
import FormModal from '../../../Components/Widget/Files/Form/Modal';
import Preview from '../../../Components/Widget/Files/Preview';
import Table from '../../../Components/Widget/Files/Table';

const UseStyles = makeStyles((theme) => ({
    form: {
        marginBottom: theme.spacing(1),
    }
}));

 const ExportDefault = (props) => {
    const { component } = props;
    const classes = UseStyles();

    return (
        <React.Fragment>
            <Uploader />
            <FormModal />
            <Preview />
            <Box className={classes.form}>
                <Button variant="contained" color="primary" onClick={() => {
                    component.prepare().getComponents().findById('files-uploader').forEach(c => c.setTitle('Upload').open());
                }}>
                    {component.ucfirst('add new file')}
                </Button>
            </Box>
            <Table />
        </React.Fragment>
    )
};

export default ExportDefault;