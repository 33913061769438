import { Tabs } from "../../../../Admin/Components/Widget/Tabs";
import Details from "../../templates/default/panel/account/details";
import Users from "../../templates/default/panel/account/users";
import Apps from "../../templates/default/panel/account/apps";

export default class AccountTabs extends Tabs {
  id = "account-tabs";

  tabs = [
    {
      id: "details",
      label: "details",
      render: Details,
    },
    {
      id: "users",
      label: "users",
      render: Users,
    },
    {
      id: "apps",
      label: "apps",
      render: Apps,
      canDisplay: ({ component }) => {
        const { account } = component.getPage().getParams();
        return component.getHelpers("auth").isAssistant(account);
      },
    },
  ];
}
