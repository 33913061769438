import { Collection } from '../../../../Core/Components/Collection';
import Account from '../../../../Api/Account';
import template from '../../templates/default/collection/accounts';

export default class Accounts extends Collection {
    id = "accounts";
    template = template
    
    onLoad(data) {
        super.onLoad(data);
        
        Account.account.collection().then(r => {
            if (r.isValid()) {
                const { rows } = r.getData();
                this.setData({'default.accounts':rows});
            } else {
                //this.getPage().redirect('/');
            }
        })
    }
}
