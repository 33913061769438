import { Modal } from "../Modal";
import templateContent from "../../../template/widget/translation/modal";

export default class TranslationModal extends Modal {
  id = "translation-modal";
  size = "lg";
  templateContent = templateContent;

  setTranslationOptions({
    account,
    app,
    repository,
    entity,
    entity_slug,
    entity_name,
  }) {
    this.setData({
      params: {
        account,
        app,
        repository,
        entity,
        entity_slug,
        entity_name,
      },
    });

    return this;
  }

  onOpen() {
    const components = this.getComponents();
    const { account, app, repository, entity, entity_slug, entity_name } =
      this.getData("params");

    components
      .findById("translation-table")
      .first()
      .setData({
        params: {
          account,
          app,
          repository,
          entity,
          entity_slug,
          entity_name,
        },
      })
      .retrieve();
  }

  prepareForm() {
    const { account, app, repository, entity, entity_slug, entity_name } =
      this.getData("params");

    this.getComponentsState().save("translation-form-form", {
      default: {
        fields: {
          entity,
          entity_slug,
          entity_name,
          position: 1,
          html: 0,
          scope: "default",
          slug: "default",
          public: 1,
          active: 1,
        },
        state: "create",
      },
      params: {
        account,
        app,
        repository,
      },
    });

    return this;
  }
}
