import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

const styles = makeStyles({
    paper: {
        padding: '10px',
        marginBottom: '10px',
        width: '100%'
    }
});

function BLink(props) {
    const { breadcrumb } = props;
    const { label } = breadcrumb;
    return <Link component={RouterLink} color="inherit" to={breadcrumb.to}>{label}</Link>;
}

function BText(props) {
    const { breadcrumb } = props;
    const { label } = breadcrumb;
    return <Typography color="textPrimary">{label}</Typography>;
}

const ExportDefault = (props) => {
    const { component } = props;
    const { build } = component.props;
    const classes = styles();
    
    return (
        <Paper elevation={3} className={classes.paper}>
            <Breadcrumbs aria-label="breadcrumb">
                {build && build(component).map((breadcrumb, index) => {
                    const key = `${component.getId()}-${index}`;
                    const props = {component,breadcrumb};
                    const { type } = breadcrumb;

                    switch(type) {
                        case 'link': {
                            return <BLink key={key} {...props} />;
                        }
                        case 'text': 
                        default: {
                            return <BText key={key} {...props} />;
                        }
                    }
                })}
            </Breadcrumbs>
        </Paper>
    );
  };

  export default ExportDefault;