import { Table } from '../../../../../../Admin/Components/Widget/Collection/Table';
import DateRangeFilterRenderer from '../../../../../../Admin/template/collection/render/dateRangeFilter';
import CheckedRenderer from '../../../../../../Admin/template/collection/render/checked';
import ActionRenderer from '../../../../../../Admin/template/collection/render/action';
import AccountApi from '../../../../../../Api/Account';

export default class AccountAppValuesTable extends Table {
    id = "account-app-values-table";
    title = 'apps';

    filters = [{
        id: 'slug',
        label: 'slug'
    },{
        id: 'name',
        label: 'name'
    },{
        id: 'created_at',
        labelFrom: 'created at (from)',
        labelTo: 'created at (to)',
        fitlerRender: DateRangeFilterRenderer
    }];

    columns = [{
        id: 'id',
        label: 'id',
        align: 'left',
        bind: 'id',
        sortable: true, 
    },{
        id: 'slug',
        label: 'slug',
        align: 'left',
        bind: 'slug',
        sortable: true,

    },{
        id: 'name',
        label: 'name',
        align: 'left',
        bind: 'name',
        sortable: true,

    },{
        id: 'data',
        label: 'data',
        align: 'left',
        bind: 'data',
        buildContent: (props) => {
            const { row } = props;
            const length = 100;
            let {  data } = row;
            return data.length > length ? data.substr(0,100)+'...': data;
        },
    },{
        id: 'public',
        label: 'public',
        align: 'left',
        bind: 'public',
        isChecked: (props) => {
            const { row } = props;
            return row.public === 1;
        },
        render: CheckedRenderer
    },{
        id: 'created_at',
        label: 'created at',
        align: 'right',
        bind: 'created_at',
        sortable: true,
    },{
        id: 'delete',
        align: 'right',
        type: 'secondary',
        text: 'delete',
        click: (props) => {
            const { component, row } = props;

            if (window.confirm(component.ucfirst('are you sure?'))) {
                const account = this.getPage().getParam('account');
                const app = this.getPage().getParam('app');
                const id = row.id;
                
                AccountApi.value.delete(account,app,id).then(r => {
                    if (r.isValid()) {
                        component.retrieve();
                    }
                });
            }
        },
        render: ActionRenderer
    },{
        id: 'edit',
        align: 'right',
        text: 'edit',
        click: (props) => {
            const { component, row } = props;
            const { id } = row;
            const { account, app } = component.getPage().getParams();

            component.getComponentsState().save('account-app-value-form',{
                'default': {
                    'fields': {},
                    'state': 'update'
                },
                'params': {
                    account,
                    app,
                    id
                }
            });

            component
                .getComponents()
                .findById('account-app-value-modal')
                .forEach(c => c.setTitle('edit value').open())
            ;
        },
        render: ActionRenderer
    }];

    getApi(options) {
        const { account, app } = this.getPage().getParams();
        return AccountApi.value.collection(account,app,options);
    }

    onLoad() {
        super.onLoad();
        this.retrieve();
    }

    afterRetrieve(r) {
        if (r.isValid()) {
            this.setCollectionData(r.getData());
        } else {
            //this.getPage().redirect('/accounts');
        }
    }
}