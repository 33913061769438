import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { component, children, value, index, ...other } = props;
  const id = component.getId();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`${id}-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index, component) {
  const id = component.getId();

  return {
    key: `${id}-tab-${index}`,
    id: `${id}-tab-${index}`,
    'aria-controls': `${id}-tabpanel-${index}`,
  };
}

const DefaultRenderTabPaner = () => ('');

const UseStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ExportDefault = (props) => {
  const { component } = props;
  const classes = UseStyles();
  const id = component.getId();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          {component.getTabs().map((tab, index) => {
            let { canDisplay } = tab;
            canDisplay = canDisplay ? canDisplay({component,tab,index}): true;

            return canDisplay && <Tab label={component.ucfirst(tab.label)} {...a11yProps(index,component)} />
          })}
        </Tabs>
      </AppBar>
      {component.getTabs().map((tab, index) => {
        const Render = tab.render || DefaultRenderTabPaner;
        const props = {component,tab,index};
        let { canDisplay } = tab;
          
        canDisplay = canDisplay ? canDisplay(props): true;

        return (
          canDisplay && 
          <TabPanel key={`${id}-tab-${index}-panel`} component={component} value={value} index={index}>
            <Render {...props} />
          </TabPanel>
        );
      })}
    </div>
  );
};

export default ExportDefault;