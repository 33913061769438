import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import OptionsTable from '../../../../../Components/Collection/Account/App/Options';
import WidgetsStyles from '../../../../../styles/widgets';

const UseStyles = makeStyles(WidgetsStyles().panel);

const ExportDefault = (props) => {
    const { component } = props;
    const classes = UseStyles();

    return (
        <React.Fragment>
            <Box className={classes.header}>
                <Button variant="contained" color="primary" onClick={() => { 
                    const { account, app } = component.getPage().getParams();

                    component.getComponentsState().save('account-app-value-form',{
                        'default': {
                            'fields': {
                                'public': 1
                            },
                            'state': 'create'
                        },
                        'params': {
                            account,
                            app
                        }
                    });

                    component
                        .getComponents()
                        .findById('account-app-value-modal')
                        .forEach(c => {
                            c.setTitle('new value').open();
                        })
                    ;
                }}>
                    {component.ucfirst('add new value')}
                </Button>
            </Box>
            <Box className={classes.main}>
                <OptionsTable />
            </Box>
        </React.Fragment>
    );
}

export default ExportDefault;