import { Crud } from '../../../../../Core/Components/Form/Crud';
import templateDefault from '../../../../../Admin/template/form/type/normal';
import TextField from '../../../../../Admin/template/form/fields/type/text';
import SwitchField from '../../../../../Admin/template/form/fields/type/switch';
import ActionField from '../../../../../Admin/template/form/fields/type/action';
import Divider from '../../../../../Admin/template/form/fields/type/divider';
import AccountApi from '../../../../../Api/Account';

export default class AccountUserForm extends Crud {
    template = templateDefault;

    id = 'account-user-form';

    fields = [{
        'id': 'user_name',
        'path': 'user_name',
        'label': 'name',
        'disabled': true,
        'fullWidth': true,
        'variant': 'filled',
        'render': TextField,
    },{
        'id': 'username',
        'path': 'user_username',
        'label': 'username',
        'disabled': true,
        'fullWidth': true,
        'variant': 'filled',
        'render': TextField,
    },{
        'id': 'assistant',
        'path': 'assistant',
        'label': 'assistant',
        'render': SwitchField,
        'canDisplay': (props) => props.form.getHelpers('auth').isSu(),
    },{
        'id': 'admin',
        'path': 'admin',
        'label': 'admin',
        'render': SwitchField,
        'canDisplay': (props) => this.isVisible(props),
    },{
        'id': 'active',
        'path': 'active',
        'label': 'active',
        'render': SwitchField,
        'canDisplay': (props) => this.isVisible(props),
    },{
        'render': Divider,
        'canDisplay': (props) => this.isVisible(props),
    },{
        'id': 'update',
        'label': 'update',
        'holder': 'action',
        'render': ActionField,
        'canDisplay': (props) => this.isVisible(props)
    }];

    onLoad() {
        super.onLoad();
        this.setData({'default.state':'update'}).retrieve();
    }

    isVisible(props) {
        const { form } = props;
        const Auth = form.getHelpers('auth');
        const { account } = form.getPage().getParams();

        const su = Auth.isSu();
        const ownerAssistant = Auth.isOwnerOrAssistant(account);
        const username = form.getData('default.fields.user_username','');
        const same = form.getHelpers('auth').getUserAttribute('username') === username;

        return su || (ownerAssistant && !same);
    }

    getApi(options, type) {
        switch(type) {
            case 'update': {
                const { account, id } = this.getPage().getParams();
                const { active, admin, assistant } = this.getData('default.fields',{});
                return AccountApi.account.user.update(account,id,{active,admin,assistant});
            }
            case 'retrieve': {
                const { account, id } = this.getPage().getParams();
                return AccountApi.account.user.one(account,id);
            }
            default: {
                //
            }
        }
    }

    afterRetrieve(r) {
        super.afterRetrieve(r);

        if (r.isValid()) {
            const { account_slug, user_name } = r.getData();

            this.getComponents().findById('page-breadcrumbs').forEach(breadcrumbs => {
                breadcrumbs
                    .setData({
                        'default' : {
                            account: {
                                slug: account_slug
                            },
                            user: {
                                name: user_name
                            }
                        }
                    })
                ;
            }); 
        }
    }
}