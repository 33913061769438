import React from 'react';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const UseStyles = makeStyles((theme) => ({
    message: {
        margin: theme.spacing(1,0,0,0),
    }
}));

const ExportDefault = (props) => {
    const { component } = props;
    const message = component.getData('success.message');
    const classes = UseStyles();

    return (
        message && <Alert severity="success" className={classes.message}>{component.ucfirst(message)}</Alert>
    );
};

export default ExportDefault;