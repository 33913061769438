import React from "react";
import Button from "@material-ui/core/Button";

const ExportDefault = (props) => {
  const { component, column } = props;
  const { text, click, type, buildContent } = column;
  const content = buildContent
    ? buildContent(props)
    : component.trans(text || "action");

  return (
    <Button color={type || "primary"} onClick={() => click && click(props)}>
      {content}
    </Button>
  );
};

export default ExportDefault;
