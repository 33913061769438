import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const UseStyles = makeStyles((theme) => ({
  action: {
    textTransform: "capitalize",
  },
}));

const ExportDefault = (props) => {
  const { form } = props;
  const classes = UseStyles();

  let { label, color, fullWidth, type, variant, onClick } = props;

  type = type ? type : "submit";
  variant = variant ? variant : "contained";
  color = color ? color : "primary";
  fullWidth = fullWidth ? true : false;
  onClick = onClick ? onClick : (e) => {};

  label = form.getHelpers("string").isString(label) ? label : label(props);

  return (
    <Button
      fullWidth={fullWidth}
      type={type}
      variant={variant}
      color={color}
      className={classes.action}
      onClick={onClick}
    >
      {form.ucfirst(label)}
    </Button>
  );
};

export default ExportDefault;
