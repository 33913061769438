import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormModal from "../../../Components/Widget/Translation/Form/Modal";
import Table from "../../../Components/Widget/Translation/Table";

const UseStyles = makeStyles((theme) => ({
  form: {
    marginBottom: theme.spacing(1),
  },
}));

const ExportDefault = (props) => {
  const { component } = props;
  const classes = UseStyles();

  return (
    <React.Fragment>
      <FormModal />
      <Box className={classes.form}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            component
              .prepareForm()
              .getComponents()
              .findById("translation-form-modal")
              .forEach((c) => c.setTitle("new translation").open());
          }}
        >
          {component.ucfirst("add new translation")}
        </Button>
      </Box>
      <Table />
    </React.Fragment>
  );
};

export default ExportDefault;
