import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  fileContainer: {
    height: "100%",
    width: "100%",
    border: "none",
  },
  imageContainer: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center",
  },
  image: {
    maxWidth: "100%",
  },
});

const ExportDefault = (props) => {
  const { component } = props;
  const { column } = component.props;
  const { width, height } = column;
  const classes = useStyles();
  const srcData = component.getSrcData();
  const isImage = component.isImage();
  const errorSrc = column.errorSrc(props);

  const style = {
    backgroundImage: `url(${isImage ? srcData : `'${errorSrc}'`})`,
    width: width,
    height: height,
  };

  return (
    <div
      className={`${classes.fileContainer} ${classes.imageContainer}`}
      style={style}
    ></div>
  );
};

export default ExportDefault;
