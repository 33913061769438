import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    width: '100vw',
    paddingTop: '50px',
    backgroundColor: '#eee',
    position: 'relative'
  },
  close: {
    position: 'absolute',
    top: 14,
    right: 14,
    cursor: 'pointer'
  },
  download: {
    position: 'absolute',
    top: 14,
    right: 50,
    cursor: 'pointer'
  },
  fileContainer: {
    height: '100%',
    width: '100%',
    border: 'none',
    borderTop: '1px solid #ccc'
  },
  imageContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center'
  },
  image: {
    maxWidth: '100%'
  }
});

const ExportDefault = (props) => {
  const { component } = props;
  const classes = useStyles();
  const open = component.getData('default.open',false);
  const srcData = component.getSrcData();
  const isImage = component.isImage();
  const { originalname } = component.getFile();
  const close = () => component.close();
  const download = () => {
    let a = document.createElement("a");
    a.href = srcData;
    a.setAttribute("download",originalname);
    a.click();
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={close}>
      {
        srcData && 
        <div className={classes.container}>
          <CloseIcon className={classes.close} onClick={close} />
          <GetAppIcon className={classes.download} onClick={download} />
          {
            isImage ? 
              <div className={`${classes.fileContainer} ${classes.imageContainer}`} style={{'backgroundImage': `url(${srcData})`}}></div>
              :
              <iframe className={classes.fileContainer} src={srcData} title={originalname} />
          }
        </div>
      }
    </Drawer>
  );
}

export default ExportDefault;